import { RESET_STATE, SET_FEEDBACK_CALL_API_CALL_STATUS } from "./feedback.actions";
import { FeedbackReducerModel } from "./feedback.models";

const initialState: FeedbackReducerModel = {
    userFeedbackApiCallStatus: null
}

export const FeedbackReducer = (state: FeedbackReducerModel = initialState, action: { type: string, payload: Record<any, any> }) => {

    switch (action.type) {
        case SET_FEEDBACK_CALL_API_CALL_STATUS:
            return {
                ...state,
                ...action.payload
            }
        
        case RESET_STATE:
            return {
                ...state,
                ...action.payload
            }
        
        default:
            return state;
    }

}