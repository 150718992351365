import Axios from "../globals/axios/axios.index";

export const VerifyOtpService = {
    verify: (email: string, otp: number, flow: string) => {
        const data: Record<string, any> = {
            email: email,
            otp: otp,
            flow: flow
        };

        return Axios.post("/api/v1/auth/verify-otp/", data);
    }
};
