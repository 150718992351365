import {
    SET_CHAT_SESSION_ID,
    SET_SEND_MESSAGE_API_STATUS,
    APPEND_MESSAGE,
    SET_MESSAGE_FEEDBACK_API_STATUS,
    RESET_STATE,
    UPDATE_MESSAGE_ID_AUDIO_URLS,
    SET_IS_NEW_CHAT,
    SET_CHAT_TYPE,
    SET_RESTART_CONVERSATION,
    UPDATE_MESSAGE_ID_VIDEO_URLS,
    CLEAR_VIDEO_URLS,
    SET_LAST_MESSAGE_DELIVERY_STATUS,
    UPDATE_MESSAGE,
    FETCH_MESSAGES_REQUEST,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAILURE,
    SET_AUDIO_MESSAGE_ID,
    SET_BOT_ANSWERING,
    SET_CHAT_TYPE_FOR_UPDATE_MSG,
    SET_SYSTEM_OVERLOAD,
    SET_MIC_CLICKED,
    SET_TOGGLE_HISTORY,
    SET_STREAM_STARTED

} from "./chat.actions";
import { ChatReducerModel } from "./chat.model";

const initialState: ChatReducerModel = {
    chatSessionId: null,
    sendMessageApiStatus: null,
    lastReceivedMessage: null,
    messages: [],
    messageFeedbackApiStatus: null,
    messageIdAudioUrls: {},
    chatType: "text",
    isNewChat: true,
    restartConversation: false,
    messageIdVideoUrls: {},
    isFetchingMessages: false,
    fetchMessagesError: null,
    audioMessageId: null,
    isBotAnswering: null,
    chatTypeForUpdateMessage: "text",
    systemOverload: {
        system_overload: false,
        message: "",
    },
    isMicClicked: 0,
    setToggleHistory: true,
    setStreamStarted: 0,
};

export const ChatReducer = (
    state: ChatReducerModel = initialState,
    action: { type: string; payload: Record<any, any> },
) => {
    switch (action.type) {
        case RESET_STATE:
            return initialState;

        case SET_CHAT_SESSION_ID:
            return {
                ...state,
                ...action.payload,
            };

        case SET_SEND_MESSAGE_API_STATUS:
            return {
                ...state,
                ...action.payload,
            };

        case SET_BOT_ANSWERING:
            return {
                ...state,
                ...action.payload,
            };

        case SET_SYSTEM_OVERLOAD:
            console.log("hello here i am");
            return {
                ...state,
                ...action.payload,
            };

        case SET_CHAT_TYPE_FOR_UPDATE_MSG:
            return {
                ...state,
                ...action.payload,
            };

        case APPEND_MESSAGE:
            let newMessages = [...state.messages, action.payload.message];
            return {
                ...state,
                ...{ lastReceivedMessage: action.payload.message },
                ...{ messages: newMessages },
            };
        case UPDATE_MESSAGE:
            let updateLastMessage = state.lastReceivedMessage || {};
            let found = false;
            let messageList = state.messages.map((obj) => {
                if (
                    obj.message_id &&
                    obj.message_id === action.payload.message.message_id
                ) {
                    found = true;
                    if (
                        state.isNewChat &&
                        state.chatTypeForUpdateMessage !== "voice"
                    ) {
                        updateLastMessage.message +=
                            action.payload.message.message;
                    }

                    // bug fix this is where second token is getting repeated twice
                    const lastTokenRepeats = obj.message.length < 20 ? obj.message.endsWith(action.payload.message.message) : null;
                    let resultMessage = lastTokenRepeats ? obj.message : obj.message + action.payload.message.message

                    return {
                        ...obj,
                        message: resultMessage,
                    };
                } else {
                    return obj;
                }

            });
            // console.log('message_bug', action.payload.message)
            if (!found && action.payload.message.sender === "assistant") {
                messageList = [...state.messages, action.payload.message];
                updateLastMessage = action.payload.message;
            }
            return {
                ...state,
                ...{ lastReceivedMessage: updateLastMessage },
                ...{ messages: messageList },
            };

        case SET_MESSAGE_FEEDBACK_API_STATUS:
            return {
                ...state,
                ...action.payload,
            };

        case UPDATE_MESSAGE_ID_AUDIO_URLS:
            let currentMessageAudioMap = { ...state.messageIdAudioUrls };
            if (currentMessageAudioMap[action.payload.messageId]) {
                currentMessageAudioMap[action.payload.messageId] =
                    currentMessageAudioMap[action.payload.messageId].concat(
                        action.payload.audioUrls,
                    );
            } else {
                currentMessageAudioMap[action.payload.messageId] =
                    action.payload.audioUrls;
            }

            return {
                ...state,
                ...{ messageIdAudioUrls: currentMessageAudioMap },
            };

        case SET_CHAT_TYPE:
            return {
                ...state,
                ...action.payload,
            };
        case SET_IS_NEW_CHAT:
            return {
                ...state,
                ...action.payload,
            };

        case SET_RESTART_CONVERSATION:
            return {
                ...state,
                ...action.payload,
            };

        case UPDATE_MESSAGE_ID_VIDEO_URLS:
            let currentMessageVideoMap = { ...state.messageIdVideoUrls };
            currentMessageVideoMap[action.payload.messageId] =
                action.payload.videoUrls;

            return {
                ...state,
                ...{ messageIdVideoUrls: currentMessageVideoMap },
            };

        case CLEAR_VIDEO_URLS:
            const messageIdVideoUrls = { ...state.messageIdVideoUrls };
            delete messageIdVideoUrls[action.payload.messageId];

            return {
                ...state,
                messageIdVideoUrls: messageIdVideoUrls,
            };

        case SET_LAST_MESSAGE_DELIVERY_STATUS:
            let messages = [...state.messages];
            let lastMessage = messages[messages.length - 1];
            lastMessage["delivery_status"] = action.payload.status;
            return {
                ...state,
                ...{ messages: messages },
            };

        case FETCH_MESSAGES_REQUEST:
            return {
                ...state,
                isFetchingMessages: true,
                fetchMessagesError: null,
            };
        case FETCH_MESSAGES_SUCCESS:
            let chatMessages = action.payload.messages;
            return {
                ...state,
                ...{
                    messages: chatMessages,
                    chatSessionId: action.payload.chatSessionId,
                    isFetchingMessages: false,
                    fetchMessagesError: null,
                },
            };
        case FETCH_MESSAGES_FAILURE:
            return {
                ...state,
                isFetchingMessages: false,
                fetchMessagesError: action.payload.error,
            };
        case SET_AUDIO_MESSAGE_ID:
            let audioMessageId = action.payload.audioMessageId;

            return {
                ...state,
                ...{
                    audioMessageId: audioMessageId,
                },
            };
        case SET_MIC_CLICKED:
            return {
                ...state,
                ...action.payload,
            };

        case SET_TOGGLE_HISTORY:
            return {
                ...state,
                ...action.payload,
            };
        case SET_STREAM_STARTED:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }

};
