import React, { useState } from 'react';
import './ContactUs.index.less'; // Make sure to import your styles
import PolygonIcon from '../../../assets/svgicons/Polygon 1.svg';
import MarshallStanding from '../../../assets/images/marshall_standing.png';
import ContactUsForm from '../ContactUsForm/ContactUsForm.index';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const ContactUs = () => {
    const [open, setOpen] = useState(false);

    const handleContactClick = () => {
        setOpen(true); // Open the modal when the button is clicked
    };

    const handleClose = () => {
        setOpen(false); // Close the modal
    };

    return (
        <div className="contact-us-container">
            <img
                className="marshall-standing-icon"
                alt="Marshall Standing"
                src={MarshallStanding}
            />
            <div className="content">
                <h1 className="heading">
                    <span className="access-marshalls-extensive-container">
                        <span>Get access </span>
                        <b className="marshalls">Marshall's</b>
                        <span> extensive knowledge repository with our </span>
                        <b className="marshalls">APIs</b>
                    </span>
                </h1>
                <div className="points">
                    <div className="point">
                        <img className="frame-item" alt="Polygon Icon" src={PolygonIcon} />
                        <div className="description">
                            Get responses crafted in Marshall's unique tone and style
                        </div>
                    </div>
                    <div className="point">
                        <img className="frame-item" alt="Polygon Icon" src={PolygonIcon} />
                        <div className="description">
                            Retrieve questions generated by our Marshall Coaching Bot along with conversation history
                        </div>
                    </div>
                    <div className="point">
                        <img className="frame-item" alt="Polygon Icon" src={PolygonIcon} />
                        <div className="description">
                            Seamless plug-and-play integration with your internal systems
                        </div>
                    </div>
                    <div className="point">
                        <img className="frame-item" alt="Polygon Icon" src={PolygonIcon} />
                        <div className="description">
                            Secure and reliable data handling
                        </div>
                    </div>
                </div>
                <div onClick={handleContactClick} className="contact-us-parent">
                    Contact Us
                </div>
            </div>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <ContactUsForm onClose={handleClose} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ContactUs;
