import Axios from "../globals/axios/axios.index";

export const RenameSessionService = {

    renameSession: (sessionId: string, newTitle: string) => {
        const authToken = localStorage.getItem("authenticationToken");
        const data = {
            session_title: newTitle
        };

        return Axios.post(`/api/v1/chat/rename-session/${sessionId}/`, data, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
        });
    }
};

