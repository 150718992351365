import Axios from "../globals/axios/axios.index";
 
export const ChatHistoryService = {
 
    fetchUserSessions: () => {
        const authToken = localStorage.getItem("authenticationToken");
        return Axios.get("/api/v1/chat/user-sessions/", {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
 
    fetchSessionMessages: (chatId: string) => {
        const authToken = localStorage.getItem("authenticationToken");
        return Axios.get(`/api/v1/chat/${chatId}/messages/`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    }
}