import React from "react";
import "./t&cPage.index.less";
import fractalLogo from "../../assets/images/fractal-logo.png";
import { Paper, Typography, Link } from "@mui/material";

const PrivacyPolicy = () => {
    // const authToken = localStorage.getItem("authenticationToken");
    // const authTokenIsValid = authToken !== null && authToken.trim().length > 0;

    // if (authTokenIsValid) {
    return (
        <Paper elevation={3} className="paperContainer">
            <div className="header">
                <img src={fractalLogo} alt="Logo" className="logo" />
                <hr className="line" />
            </div>
            <div>
                <h2>Privacy Policy</h2>
            </div>
            <div>
                <p>Privacy Notice</p>
            </div>
            <div>
                <p>
                    This Privacy Notice (the <strong>“Notice”</strong>) sets out how we, <strong>Fractal Analytics Private Limited</strong>, Level 7, Commerz II, International Business Park, Oberoi Garden City, Western Express Highway, Goregaon (E), Mumbai 400 063, India  and our affiliates, our subsidiaries & associated offices (collectively <strong>“Fractal”, “we”, “us”, “our”</strong>) process the personal data of individuals (each a <strong>“User”, “you”, “your”</strong>) who contact us or who use our websites, services, programs, content and related features (collectively, the <strong>“Services”</strong>).
                </p>
                <p>
                    If you have any questions about this Notice, please contact us by <strong>info@fractal.ai</strong>
                </p>
                <p>
                    This notice, together with our <Link href="/t&c"><strong style={{ color: "blue", fontStyle: "italic" }}>Website Terms of Use</strong></Link> and any other documents referred to in these documents, set out our views and practices regarding your personal data and how we will treat it. Please read these documents carefully. By visiting our website or using our Services, you acknowledge the processing described in this Notice, our Website Terms and related documents.
                </p>
                <p>
                    We will let you know, by posting on our website or otherwise, if we make any changes to this Notice from time to time. Your continued use of the Services after notifying such changes will amount to your acknowledgement of the amended Notice. This version of our privacy notice was published in February 2020.
                </p>
                <p>
                    As described below, your personal data may be collected by Fractal and all companies wholly or partially owned by it, disclosed to third parties, and used by third parties and Fractal.
                </p>
                <h3>
                    What is personal data?
                </h3>
                <p>
                    <strong>“Personal data”</strong> means any information relating to an identified or identifiable natural person, known as <strong>‘data subject’</strong>, who can be identified directly or indirectly; it may include name, address, email address, phone number, IP address, location data, cookies, call recording and similar information. It may also include <strong>“special categories of personal data”</strong> such as racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a data subject, data concerning health or data concerning a natural person’s sexual orientation.
                </p>
                <h3>
                    What personal data do we process?
                </h3>
                <p>
                    We may process the following personal data:
                </p>
                <p className="indent1">
                    a.	Information provided by you. You may give us information about you by filling in forms such as the contact form on our website or the Content Download Form, making applications in respect of job postings, corresponding with us by e-mail, phone or otherwise. This information may include your name, mailing address (including zip code) email address, employer, job title and department, telephone number and facsimile numbers, the content of any email you send to us and other personal identifying information.
                </p>
                <p className="indent1">
                    b.	Information about others. You may also provide to us personal data relating to third parties, such as people who you work with, reporters and other individuals who comment on markets and news, business actors relevant to your industry and other individuals.
                </p>
                <p className="indent1">
                    c.	Information about your device. With regard to each of your visits to our website we may collect technical information about your device.  This information is compiled and analyzed on both a personal and an aggregated basis. This information may include the Web site’s Uniform Resource Locator (“URL”) that you just came from, which URL you go to next, what browser you are using, and your Internet Protocol (“IP”) address. A URL is the global address of documents and other resources on the World Wide Web. An IP address is an identifier for a computer or device on a Transmission Control Protocol/Internet Protocol (“TCP/IP”) network, such as the World Wide Web. Networks like the World Wide Web use the TCP/IP protocol to route information based on the IP address of the destination. In other words, an IP address is a number that is automatically assigned to your computer whenever you are surfing the web, allowing web servers to locate and identify your computer. Computers require IP addresses in order for users to communicate on the Internet.
                </p>
                <p className="indent1">
                    d.	Information from third party sources. This may include information received from our service providers and other third parties providing us with your information which is publically available or collected by them.
                </p>
                <p>
                    Generally, you are under no obligation to provide this information, but without it, we may be unable to provide you with some of our Services. Some personal data will be mandatory in order for us to comply with mandatory client due diligence processes before we are able to provide the Services.We do NOT collect sensitive personal data of website users such as financial information such as bank account or credit card or debit card or other payment instrument details, physical, physiological and mental health condition, sexual orientation, medical records and history and biometric information.Information about third parties should only be provided if you have demonstrable permission to do so or if the information is available in the public domain.
                </p>
                <p>
                    We will rely on the information provided by you as accurate, complete and up to date and you agree to ensure that this will be the case.
                </p>
                <p>
                    You must be at least 18 years old to have our permission to use this site. Our policy is that we do not knowingly collect, use or disclose Personal data about visitors that are under 18 years of age.
                </p>
                <h3>
                    How do we use your personal data?
                </h3>
                <p>
                    We will only process personal data, in accordance with applicable law, for the following purposes:
                </p>
                <p className="indent1">
                    a.	Responding to your queries, requests and other communications
                </p>
                <p className="indent1">
                    b.	Providing the Services including our website and related features as well as providing other information services.
                </p>
                <p className="indent1">
                    c.	Resolve disputes, troubleshoot problems and enforce our agreements with you, including our Site Terms of Use, Sales Terms and Conditions, and this Private Policy
                </p>
                <p className="indent1">
                    d.	Enabling suppliers and service providers to carry out certain functions on our behalf in order to provide the Services such as sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, etc. These third party service providers have access to personal data needed to perform their functions, but may not use it for other purposes. We do not sell, rent, share, or otherwise disclose personally data from customers for commercial purposes.
                </p>
                <p className="indent1">
                    e.	Register to subscribe to newsletters
                </p>
                <p className="indent1">
                    f.	Participate in discussion forums
                </p>
                <p className="indent1">
                    g.	Diagnose problems with our servers, software, to administer our site, to gather demographic information and to track visitors to our website
                </p>
                <p className="indent1">
                    h.	Sending you personalized marketing communications based on your interests opted upon filling forms on our website
                </p>
                <p className="indent1">
                    i.	Serving personalized advertising to your devices, based on your interests in accordance with our Cookie Statement. You have the right to disable cookies as set out in Cookie statement. Click here to access our Cookie Statement.
                </p>
                <p className="indent1">
                    j.	Complying with applicable law, including in response to a lawful request from a court or regulatory body.
                </p>
                <p className="indent1">
                    The legal basis for our processing of personal data for the purposes described above will typically include:
                </p>
                <p className="indent2">
                    <strong>•</strong> processing necessary for our or a third party’s legitimate interests, such as processing for the purposes set out in paragraphs (a), (b), (c), (d), (e), (f), (g) and (h) which is carried out on the basis of our legitimate interests to ensure that Services are properly provided,
                </p>
                <p className="indent2">
                    <strong>•</strong>	your consent, such as processing for the purposes set out in paragraphs (i);
                </p>
                <p className="indent2">
                    <strong>•</strong> processing necessary for compliance with a legal obligation to which we are subject, such as processing for the purposes set out in paragraph (j); and
                </p>
                <p className="indent2">
                    <strong>•</strong>	any other applicable legal grounds.
                </p>
                <h3>
                    Disclosure of personal data
                </h3>
                <p>
                    There are circumstances where we may wish to disclose or are compelled to disclose your personal data to third parties. These scenarios include disclosure to:
                </p>
                <p className="indent1">
                    <strong>•</strong> our <strong>affiliates and subsidiaries</strong>;
                </p>
                <p className="indent1">
                    <strong>•</strong>	our <strong>service providers</strong> who capture and store data collected through the forms that are filled by visitors to our website;
                </p>
                <p className="indent1">
                    <strong>•</strong> subject to appropriate legal basis such as consent, our <strong>advertising</strong> and <strong>marketing</strong> teams who enable us, for example, to deliver personalized ads to your devices or who may contact you by email, telephone, SMS or by other means;
                </p>
                <p className="indent1">
                    <strong>•</strong>	our <strong>service partners</strong> to operate our website and better serve the visitors to our website:
                </p>
                <p className="indent1">
                    <strong>•	payer</strong>, such as your employer, who pays for our products and services;
                </p>
                <p className="indent1">
                    <strong>•	public authorities</strong> where we are required by law to do so; and
                </p>
                <p className="indent1">
                    <strong>•	other third parties</strong> where you have provided your consent.
                </p>
                <p>
                    The service providers are contractually bound not to share Personal data collected from visitors to our website with anyone else.
                </p>
                <h3>
                    Security Practices
                </h3>
                <p>
                    At our site you can be assured that your Personal data is secure and consistent with current industry standards. The importance of security for all Personal data associated with our user is of utmost concern to us. We do not do any transmission of the Personal data collected through our website. Our service partners, who assist us in capturing and storing Personal data, are contractually bound not to share that information with any other party. Our company has a requisite information security policy in place with appropriate managerial, technical, operational and physical security control measures to protect the personal and non-personal information that is collected from website users.
                </p>
                <p>
                    Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal data, you acknowledge that:
                </p>
                <p className="indent1">
                    a.	there are security and privacy limitations of the Internet which are beyond our control;
                </p>
                <p className="indent1">
                    b.	the security, integrity and privacy of any and all information and data exchanged between you and us through this site cannot be guaranteed; and
                </p>
                <p className="indent1">
                    c.	any such information and data may be viewed or tampered with in transit by a third party.
                </p>
                <h3>
                    Retention of personal data
                </h3>
                <p>
                    We retain personal data for as long as is necessary for the purposes listed above or longer as may be required by the law or as per the client requirement. Please contact us for further details of applicable retention periods.
                </p>
                <h3>
                    Data subject rights
                </h3>
                <p>
                    Data subjects may have numerous rights in relation to their personal data.
                </p>
                <p className="indent1">
                    <strong>• Right to make a subject access request (SAR):</strong> Data subjects may request in writing copies of their personal data. However, compliance with such requests is subject to certain limitations and exemptions and the rights of other individuals. Each request should make clear that a SAR is being made. You may also be required to submit a proof of your identity and any payment permitted by law, where applicable.
                </p>
                <p className="indent1">
                    <strong>• Right to rectification:</strong> Data subjects may request that we rectify any inaccurate or incomplete personal data.
                </p>
                <p className="indent1">
                    <strong>• Right to withdraw consent:</strong> Data subjects may at any time withdraw their consent to the processing of their personal data carried out by us on the basis of their previous consent. Such withdrawal will not affect the lawfulness of processing based on such previous consent.
                </p>
                <p className="indent1">
                    <strong>• Right to object to processing including automated processing and profiling:</strong> We do not make automated decisions about data subjects. However, we may rely on information provided by third parties such as credit reference agencies which may score data subjects on the basis of automated decisions. Profiling may be carried out for business administration purposes, such as monitoring trends in User visits of our website and in order to deliver relevant ads to Users’ devices. We will comply with valid objection requests unless we have a compelling overriding legitimate ground for the continuation of our processing or we have another lawful reason to refuse such request. We will comply with each valid opt-out request in relation to marketing communications.
                </p>
                <p className="indent1">
                    <strong>• Right to erasure:</strong> Data subjects may request that we erase their personal data. We will comply, unless there is a lawful reason for not doing so. For example, there may be an overriding legitimate ground for keeping the personal data, such as, our business record retention obligations that we have to comply with.
                </p>
                <p className="indent1">
                    <strong>• Restriction:</strong> Data subjects may request that we restrict our processing of their personal data in various circumstances. We will comply, unless there is a lawful reason for not doing so, such as, a legal obligation to continue processing your personal data in a certain way.
                </p>
                <p className="indent1">
                    <strong>• Right to data portability:</strong> In certain circumstances, data subjects may request the controller to provide a copy of their personal data in a structured, commonly used and machine-readable format and have it transferred to another provider of the same or similar services. We do not consider that this right applies to our Services. However, to the extent it does, we will comply with such transfer request. Please note that a transfer to another provider does not imply erasure of the data subject’s personal data which may still be retained for legitimate and lawful purposes.
                </p>
                <p className="indent1">
                    <strong>• Right to lodge a complaint with the supervisory authority:</strong> We suggest that data subjects contact us about any questions or complaints in relation to how we process their personal data. However, each data subject has the right to contact the relevant supervisory authority directly.
                </p>
                <h3>
                    The California Consumer Privacy Act
                </h3>
                <p>
                    Under the California Consumer Privacy Act (‘CCPA’), California residents have certain rights regarding the personal information that businesses have about them. This includes the rights to request access or deletion of your personal information, as well as the right to direct a business to stop selling your personal information.
                </p>
                <h3>
                    How We Collect Your Personal Information
                </h3>
                <p>
                    We collect identifiers and commercial information directly from you.
                </p>
                <p>
                    We collect Internet or other electronic network activity from your usage of the Fractal website and its services.
                </p>
                <h3>
                    With Whom We Share and Sell Your Personal Information
                </h3>
                <p>
                    Fractal shares personal information as necessary for certain “business purposes,” as defined by the CCPA (Cal. Civ. Code 1798.140(d)). This includes sharing identifiers, commercial information and internet or other electronic network activity with providers of service to customer relationship management, consulting, email, product feedback and helpdesk services.
                </p>
                <p>
                    While Fractal does not sell personal information in exchange for any monetary consideration, we do share personal information for other benefits that could be deemed a “sale,” as defined by the CCPA (Cal. Civ. Code 1798.140(t)(1)). This includes sharing identifiers, commercial information and internet or other electronic network activity with advertising networks, website analytics companies.
                </p>
                <h3>
                    Data subject rights
                </h3>
                <p className="indent1">
                    <strong>• Right to Opt-out of Sale –</strong> While Fractal does not sell personal information in exchange for any monetary consideration, we do share personal information for other benefits that could be deemed a “sale,” as defined by the CCPA (Cal. Civ. Code 1798.140(t)(1)). We support the CCPA and wish to provide you with control over how your personal information is collected and shared.
                </p>
                <p className="indent1">
                    You have the right to direct Fractal to not sell your personal information. You may reach out to dpo@fractal.ai to exercise your right.
                </p>
                <p className="indent1">
                    With respect to cookies, you can always customize your settings at any time.
                </p>
                <p className="indent1">
                    Please note that we may still use aggregated and de-identified personal information that does not identify you or any individual; we may also retain information as needed in order to comply with legal obligations, enforce agreements, and resolve disputes.
                </p>
                <p className="indent1">
                    <strong>• Right to Request Disclosure –</strong> You have the right to request disclosure about what categories of personal information Fractal has sold or disclosed for a business purpose about you and the categories of third parties to whom the personal information was sold or disclosed. Additionally, you have the right to request disclosure of specific pieces of information. Below is a full list of the information that you can include in your request.
                </p>
                <p className="indent1">
                    <strong>• Right to Request Deletion –</strong> You have the right to request that Fractal delete any personal information about your that Fractal has collected from you. Please note that there are exceptions where Fractal does not have to fulfill a request to delete information, such as when the deletion of information would create problems with the completion of a transaction or compliance with a legal obligation.
                </p>
                <p className="indent2">
                    <strong>•</strong> The categories of personal information that Fractal has collected about you
                </p>
                <p className="indent2">
                    <strong>•</strong> The categories of sources from which Fractal collected the personal information
                </p>
                <p className="indent2">
                    <strong>•</strong> The business or commercial purpose for collecting or selling the personal information
                </p>
                <p className="indent2">
                    <strong>•</strong> The categories of third parties with whom Fractal shares personal information
                </p>
                <p className="indent2">
                    <strong>•</strong> The specific pieces of personal information Fractal has collected about you
                </p>
                <p className="indent2">
                    <strong>•</strong> The categories of personal information that Fractal disclosed about you for a business purpose
                </p>
                <p className="indent2">
                    <strong>•</strong> The categories of personal information that Fractal has sold about you, as well as the categories of third parties to whom Fractal sold the information.
                </p>
                <h3>
                    California and Delaware “Do Not Track” Disclosures
                </h3>
                <p>
                    California and Delaware law require Fractal to indicate whether it honors “Do Not Track” settings in your browser concerning targeted advertising. Fractal adheres to the standards set out in this Privacy Notice and does not monitor or respond to Do Not Track browser requests.
                </p>
                <h3>
                    Children
                </h3>
                <p>
                    We do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any Personal Information about yourself to us. If we learn that we have collected Personal Information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us Personal Information, please contact us at dpo@fractal.ai
                </p>
                <h3>
                    Changes to the Privacy Notice
                </h3>
                <p>
                    We’re constantly trying to improve our Websites and Services, so we may need to change this Privacy Notice from time to time as well. We will alert you to material changes by, for example, placing a notice on our Websites and/or by sending you an email (if you have registered your e-mail details with us) when we are required to do so by applicable law. You can see when this Privacy Notice was last updated by checking the date at the top of this page. You are responsible for periodically reviewing this Privacy Notice.
                </p>
            </div>
        </Paper>
    )
    // }
    // else {
    //     return (
    //         <Paper className="no-login-screen">
    //             <Typography variant="body1" align="center">
    //                 Please Login to see the Terms of Use!
    //             </Typography>
    //         </Paper>
    //     )
    // }
}

export default PrivacyPolicy