import Axios from "../globals/axios/axios.index";

export const RegistrationService = {
    registration: (username: string, email:string, first_name:string, last_name:string, password: string) => {
        const data: Record<any, any> = {
            username: username,
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password
        }

        return Axios.post("/api/v1/auth/register/", data);
    }
}