import { SET_ASK_API_CALL_STATUS, SET_USER_FEEDBACK_API_CALL_STATUS, SET_FEEDBACK_CODES, SET_FEEDBACK_CODE_API_CALL_STATUS, SET_SELECTED_USER_FEEDBACK_ACTION, SET_QUESTION_ID, RESET_STATE_FOR_NEW_QUESTION, SET_QUESTION_ANSWER } from "./qAndA.actions";
import { QAndAStateReducerModel } from "./qAndA.models";

const initialState: QAndAStateReducerModel = {
    questionAnswer: {},
    askApiCallStatus: null,
    feedbackCodes: [],
    questionId: null,
    selectedUserFeedbackAction: null,
    userFeedbackApiCallStatus: null,
}

export const QAndAReducer = (state: QAndAStateReducerModel = initialState, action: { type: string, payload: Record<any, any> }) => {

    switch (action.type) {
        case SET_QUESTION_ANSWER:
            return {
                ...state,
                ...action.payload
            }

        case SET_ASK_API_CALL_STATUS:
            return {
                ...state,
                ...action.payload
            }

        case SET_FEEDBACK_CODE_API_CALL_STATUS:
            return {
                ...state,
                ...action.payload
            }

        case SET_FEEDBACK_CODES:
            return {
                ...state,
                ...action.payload
            }
        
        case SET_QUESTION_ID:
            return {
                ...state,
                ...action.payload
            }
        
        case SET_SELECTED_USER_FEEDBACK_ACTION:
            return {
                ...state,
                ...action.payload
            }

        case SET_USER_FEEDBACK_API_CALL_STATUS:
            return {
                ...state,
                ...action.payload
            }
        
        case RESET_STATE_FOR_NEW_QUESTION:
            return {
                ...state,
                ...action.payload
            }

        default:
            return state;
    }

}