import { SET_AUTHENTICATION_STATUS } from "./login.actions"
import { LoginStateReducerModel } from "./login.models"

const initialState: LoginStateReducerModel = {
    authenticationStatus: null
}

export const LoginReducer = (state: LoginStateReducerModel = initialState, action: { type: string, payload: Record<any, any> }) => {
    
    switch (action.type) {
        case SET_AUTHENTICATION_STATUS:
            return {
                ...state,
                ...action.payload
            };
        
        default:
            return state;
    }
}