import Axios from "../globals/axios/axios.index";

export const UpdatePasswordService = {
    updatePassword: (email: string, newPassword: string, otp: string) => {
        const data: Record<any, any> = {
            email: email,
            new_password: newPassword,
            otp: otp
        };

        return Axios.post("/api/v1/auth/update-password/", data);
    }
};
