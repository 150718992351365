import React from "react";
import { Button, Dialog, DialogActions, Typography } from "@mui/material";
import { Mic } from "@mui/icons-material";

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isDisabled?: boolean
}

const VoiceListening:React.FC<DialogProps> = ({
    isOpen,
    onClose,
    onConfirm,
    isDisabled = false
}) => {
    return (
        <Dialog open={isOpen} onClose={(event, reason) => {
            if (reason === "escapeKeyDown" || reason === "backdropClick") {
                return
            }
            onClose();
        }}>
            <div className="dialog-content">
                <div className="icon-container">
                    <Mic className="icon" />
                </div>
                <Typography className="text" variant="body1">Listening to your message...</Typography>
            </div>
            <DialogActions>
                <Button variant="text" color="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="outlined" color="primary" onClick={onConfirm} disabled={isDisabled}>Send</Button>
            </DialogActions>
        </Dialog>        
    )
}

export default VoiceListening;