import { Dispatch } from "redux";
import appStateModel from "../../globals/redux/models";
import * as GlobalActions from "./layout.actions";
import React from "react";

export const resetState = () => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(GlobalActions.resetState());
    };
};

export const closeNotificationMessage = () => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(GlobalActions.closeNotification());
    };
};

export const setNotificationMessage = (
    showNotification: boolean,
    notificationMessage: string | null,
    notificationSeverity: string | null,
) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(
            GlobalActions.setNotification(
                showNotification,
                notificationMessage,
                notificationSeverity,
            ),
        );
    };
};

export const setHeaderRightComponent = (
    component: React.ReactElement
) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(
            GlobalActions.setHeaderRightComponent(component)
        )
    }
}