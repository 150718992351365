import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { VerifyOtpService } from '../../services/verifyOtpService';
import { ResendOtpService } from '../../services/resendOtpService';
import { Collapse, Typography, Button } from '@mui/material';
import OTPInput from "react-otp-input";
import "./verifyotp.index.less";
import mgLogoImageUrl from "../../assets/images/mg-logo.png";
import fractalLogo from "../../assets/images/fractal-logo.png";

const VerifyOtpPage = () => {
    const [userEnteredOtp, setUserEnteredOtp] = useState("");
    const [resendStatus, setResendStatus] = useState("");
    const [verificationStatus, setVerificationStatus] = useState("");
    const [verificationStatusOpen, setVerificationStatusOpen] = useState(false);
    const [resendStatusOpen, setResendStatusOpen] = useState(false);
    const [disableResend, setDisableResend] = useState(true);
    const [resendCountdown, setResendCountdown] = useState(120);
    const [successMsg, setSuccessMsg] = useState(false);

    const navigate = useNavigate()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const flow = queryParams.get("flow");

    const handleOtpChange = (otp: string) => {
        setUserEnteredOtp(otp);
    }

    useEffect(() => {
        document.title = "Verify OTP";
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (resendCountdown > 0) {
                setResendCountdown(prevCountdown => prevCountdown - 1);
            } else {
                setDisableResend(false); // Enable Resend button after 120 seconds
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [resendCountdown]);

    const verifyOtp = async () => {

        if ("gtag" in window) {
            (window as any).gtag("event", "click", {
                event_category: "custom",
                event_label: "verify_otp_button_clicked",
                value: "verify_otp_button_clicked"
            }, { "debug_mode": true });
        }

        try {
            const verifyResponse = await VerifyOtpService.verify(email, parseInt(userEnteredOtp), flow);
            if (verifyResponse.status === 200) {
                setVerificationStatus("OTP verified successfully.");
                setVerificationStatusOpen(true);
                setSuccessMsg(true)
                setTimeout(() => {
                    if (flow === "register")
                        navigate("/login");
                    else
                        navigate(`/update-password?email=${email}`)
                }, 500);
            } else {
                if (verifyResponse.data && verifyResponse.data.errors) {
                    setVerificationStatus("OTP verification failed. " + verifyResponse.data.errors);
                    setVerificationStatusOpen(true);
                    setSuccessMsg(false)
                    setTimeout(() => {
                        setVerificationStatusOpen(false); // Hide status message
                    }, 3000);
                } else {
                    setVerificationStatus("OTP verification failed.");
                    setVerificationStatusOpen(true);
                    setSuccessMsg(false)
                    setTimeout(() => {
                        setVerificationStatusOpen(false); // Hide status message
                    }, 3000);
                }
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                const errorMessage = "OTP verification failed. " + error.response.data.error + ".";
                setVerificationStatus(errorMessage);
                setVerificationStatusOpen(true);
                setSuccessMsg(false);
            } else {
                setVerificationStatus("An error occurred during OTP verification.");
                setVerificationStatusOpen(true);
                setSuccessMsg(false);
            }
            setTimeout(() => {
                setVerificationStatusOpen(false); // Hide status message
            }, 3000);
        }
    };

    const resendOtp = async () => {
        try {
            const resendResponse = await ResendOtpService.resend(email, flow);
            if (resendResponse.status === 200) {
                setResendStatus("New OTP sent to your email");
                setResendStatusOpen(true);
                setDisableResend(true);
                setResendCountdown(120); // Start countdown for 120 seconds
                setSuccessMsg(true)
                setTimeout(() => {
                    setResendStatusOpen(false); // Hide resend status message
                }, 3000);
            } else {
                setResendStatus("Failed to resend OTP.");
                setResendStatusOpen(true);
                setSuccessMsg(false)
                setTimeout(() => {
                    setResendStatusOpen(false); // Hide resend status message
                }, 3000);
            }
        } catch (error) {
            setResendStatus("An error occurred while resending OTP.");
            setResendStatusOpen(true);
            setSuccessMsg(false)
            setTimeout(() => {
                setResendStatusOpen(false); // Hide resend status message
            }, 3000);
        }
    };

    // CSS for OTP Input boxes
    type TextAlign = "left" | "right" | "center" | "justify" | "initial" | "inherit";
    const defaultStyles: React.CSSProperties = {
        width: "64px",
        textAlign: "center",
        height: "64px",
        fontSize: "24px",
    };
    const smallScreenStyles: React.CSSProperties = {
        width: "40px",
        textAlign: "center",
        height: "40px",
        fontSize: "18px",
    };
    const screenWidth = window.innerWidth;
    const styles = screenWidth <= 480 ? smallScreenStyles : defaultStyles;

    return (
        <div className="verify">
            <div className="maincontainer">
                <div style={{ marginBottom: "8px" }}>
                    <img className="logoImage" src={mgLogoImageUrl} alt="Logo" />
                </div>
                {/* <h2 className="maintitle">One Time Password Verification</h2> */}
                <Typography className="verify-maintitle">Enter the One Time Password sent to {email}</Typography>
                <div className="form-field">
                    {/* <TextField
                        type="text"
                        placeholder="Enter OTP"
                        value={userEnteredOtp}
                        onChange={(e) => setUserEnteredOtp(e.target.value)}
                    /> */}
                    <OTPInput
                        value={userEnteredOtp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        containerStyle={{ gap: "10px" }}
                        renderInput={(inputProps, index) => (
                            <input
                                {...inputProps}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                style={styles}
                            />
                        )
                        }
                    />
                </div>
                <Typography variant="body2" color="textSecondary">
                    {disableResend
                        ? `Resend OTP in ${resendCountdown}s`
                        : ""}
                </Typography>
                <div className="button-container">
                    <span className="button-span">
                        <Button variant="contained" color="primary" onClick={verifyOtp} style={{ textTransform: "none" }}>
                            Verify OTP
                        </Button>
                    </span>
                    <span className="button-span">
                        <Button variant="contained" onClick={resendOtp} disabled={disableResend} style={{ textTransform: "none" }}>
                            Resend OTP
                        </Button>
                    </span>
                </div>
                <div>
                    <Collapse orientation="vertical" in={verificationStatusOpen}>
                        <Typography className={`status ${successMsg ? "success" : "error"}`}>{verificationStatus}</Typography>
                    </Collapse>
                    <Collapse orientation="vertical" in={resendStatusOpen}>
                        <Typography className={`status ${successMsg ? "success" : "error"}`}>{resendStatus}</Typography>
                    </Collapse>
                </div>
            </div>
            <div className="logo-container">
                <span className="powered-by-text">Powered by</span>
                <img src={fractalLogo} alt="Logo" className="logo-image" />
            </div>
        </div>
    );
};

export default VerifyOtpPage;
