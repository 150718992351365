import Axios from "../globals/axios/axios.index";

export const ChatService = {
    getNewChatSessionId: () => {
        return Axios.post("/api/v1/chat/");
    },
    postMessage: (chatId: string, postData: Record<any, any>) => {
        return Axios.post(
            `/api/v1/chat/${chatId}/messages-with-audio/`,
            postData,
        );
    },
    getIndependantAudioFromMessageId: (messageId: string, sessionId: string) => {
        return Axios.post('/api/v1/chat/get_audio_from_text/', {
            message_id: messageId,
            session_id: sessionId
        });
    },
    postTextMessage: (chatId: string, postData: Record<any, any>) => {
        return Axios.post(`/api/v1/chat/${chatId}/messages/`, postData);
    },
    sendFeedback: (
        messageId: string,
        action: "TU" | "TD",
        text: string | null,
        checkboxResponses: string[],
    ) => {
        return Axios.post(`/api/v1/chat/messages/${messageId}/feedback/`, {
            action: action,
            feedback: text,
            feedback_options: checkboxResponses,
        });
    },
    setMessageType: (
        messageId: string,
    ) => {
        return Axios.post(`/api/v1/chat/messages/${messageId}/mark-type/`);
    },
    textToAudio: (messageId: string) => {
        return Axios.post(
            `/api/v1/chat/messages/${messageId}/text-to-audio-t/`,
        );
    },
    // textToVideo: (messageId: string) => {
    //     return Axios.post(`/api/v1/chat/messages/${messageId}/text-to-video-t/`);
    // }
    textToVideo: (messageId: string) => {
        return Axios.post(
            `/api/v1/chat/messages/${messageId}/text-to-video-s/`,
        );
    },
};
