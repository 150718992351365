import React, { useRef, useState, useEffect } from "react";
import userImageUrl from "../../../../assets/images/user.png";
import botImageUrl from "../../../../assets/images/mg-face-crop.jpeg";
import Parser from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { IconButton, Snackbar, Dialog, DialogContent, DialogTitle, Button, DialogActions } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import {
    DoneAll,
    Refresh,
    ThumbDownAltOutlined,
    ThumbUpAltOutlined,
} from "@mui/icons-material";
import ReplyIcon from '@mui/icons-material/Reply';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ShareTextOnTwitter, ShareIcons } from "./socialShare";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Typewriter from "typewriter-effect";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../globals/redux/store/store.index";
import ReactMarkdown from "react-markdown";
import { EmailIcon, FacebookIcon, LinkedinIcon, WhatsappIcon } from "react-share";

interface Props {
    messageId: string;
    text: string;
    sender: string;
    onFeedbackButtonClicked: (
        messageId: string,
        action: "TU" | "TD",
        feedbackText: string | null,
        checkboxResponses: string[],
    ) => void;
    sendMessageApiStatus: "inprogress" | "complete" | "failed" | null;
    messageFeedbackApiStatus: "inprogress" | "complete" | "failed" | null;
    onThumbsDownButtonClicked: (messageId: string) => void;
    onTextContainerResized: () => void;
    onRetryButtonClicked: () => void;
    feedbackAction?: "TU" | "TD" | null;
    deliveryStatus?: "delivered" | "failed";
    forHistory?: string;
}

const TextMessage: React.FC<Props> = ({
    messageId,
    text,
    sender,
    onFeedbackButtonClicked,
    sendMessageApiStatus,
    messageFeedbackApiStatus,
    onThumbsDownButtonClicked,
    feedbackAction = null,
    onTextContainerResized,
    onRetryButtonClicked,
    deliveryStatus,
    forHistory,
}) => {
    let className = "sent message";
    let imageUrl = userImageUrl;
    const textRef = useRef<HTMLDivElement>();
    const chatState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.chatV2Reducer,
    );
    const [modifiedText, setModifiedText] = useState(text);

    if (sender === "assistant") {
        className = "received message";
        imageUrl = botImageUrl;
    }

    useEffect(() => {
        if (
            chatState.lastReceivedMessage &&
            chatState.lastReceivedMessage.message_id &&
            chatState.lastReceivedMessage.message_id === messageId
        ) {
            setModifiedText(chatState.lastReceivedMessage.message);
        }
    }, [chatState.lastReceivedMessage, chatState.lastReceivedMessage?.message]);

    useEffect(() => {
        let resizeObserver = new ResizeObserver(() => {
            onTextContainerResized();
        });
        resizeObserver.observe(textRef.current);
        // eslint-disable-next-line
    }, []);


    const [isHoveredTU, setIsHoveredTU] = useState(false);
    const [isHoveredTD, setIsHoveredTD] = useState(false);
    const [isHoveredCopy, setIsHoveredCopy] = useState(false);
    const [isHoveredShare, setIsHoveredShare] = useState(false);
    const [feedbackSelected, setFeedbackSelected] = useState(null);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    const copyToClipboard = () => {
        copy(modifiedText);
        setIsSnackbarOpen(true);
    };

    const findTargetText = (messageId) => {
        let previousMessage = { message_id: 1, message: "test" };

        const messageIndex = chatState.messages.findIndex(msg => msg.message_id === messageId);

        if (messageIndex !== -1) {
            if (messageIndex > 0) {
                previousMessage = {
                    message_id: chatState.messages[messageIndex - 1].message_id,
                    message: chatState.messages[messageIndex - 1].message
                };
            }
        }

        return previousMessage;
    };

    const textToShare = forHistory === "history" ? text : modifiedText;
    const combinedText = `${findTargetText(messageId).message}\n\n${textToShare}`;
    const twitterText = `Check out QnA with Marshall's AI Avatar. Great insights there. Give it a try at`;
    const QnAText = `Question: ${findTargetText(messageId).message}\n\nAnswer: ${textToShare}`;

    const [redirectMessage, setRedirectMessage] = useState("")
    const [isOpen, setIsOpen] = useState(false);

    const openDialog = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const handleEmailClick = () => {
        const subject = encodeURIComponent("Check out my QnA with Marshall's AI Avatar");
        const body = encodeURIComponent(`Below is the conversation I had with Dr. Marshall. I found it quite insightful. Check it out. You too may want to give it a try!\n\n\ Question: ${findTargetText(messageId).message}\n\n Answer: ${textToShare.substring(0, 1150)}\n\n Give it a try at ${"https://marshallgoldsmith.ai/"}`);

        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.open(mailtoLink, '_blank');
    };

    const handleWhatsAppClick = () => {
        const body = encodeURIComponent(`Check out my QnA with Marshall's AI Avatar\n\nGreat insights!\n\nQuestion: ${findTargetText(messageId).message}\n\nAnswer: ${textToShare}\n\nGive it a try at ${"https://marshallgoldsmith.ai/"}`);

        const Link = ` https://wa.me/?text=${body}`;
        window.open(Link, '_blank');
    };

    const [snackbarOpenShare, setSnackbarOpenShare] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(5);

    useEffect(() => {
        let countdown;
        if (snackbarOpenShare) {
            countdown = setInterval(() => {
                setSecondsLeft((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else {
            setSecondsLeft(5);
        }

        return () => clearInterval(countdown);
    }, [snackbarOpenShare]);

    const handleLinkedInClick = () => {
        // Copy text to clipboard
        copy(QnAText);
        // Show popup message
        setSnackbarOpenShare(true);
        setRedirectMessage("LinkedIn");
        // Hide popup after 5 seconds
        setTimeout(() => {
            setSnackbarOpenShare(false);
            // Redirect to LinkedIn Share Dialog
            const ShareLink = "https://www.linkedin.com/sharing/share-offsite/?url=https://marshallgoldsmith.ai";
            window.open(ShareLink, '__blank');
        }, 5000);
    };

    const handleFacebookClick = () => {
        // Copy text to clipboard
        copy(QnAText);
        // Show popup message
        setSnackbarOpenShare(true);
        setRedirectMessage("Meta");
        // Hide popup after 5 seconds
        setTimeout(() => {
            setSnackbarOpenShare(false);
            // Redirect to LinkedIn Share Dialog
            const FbShareLink = "https://www.facebook.com/sharer/sharer.php?u=https://marshallgoldsmith.ai";
            window.open(FbShareLink, '__blank');
        }, 5000);
    };

    const handleShareSnackbarClose = () => {
        setSnackbarOpenShare(false);
    };
    return (
        <div className={className}>
            <div className={sender === "user" ? "top-user" : "top"}>
                {/* {
                    (sender === "user" && deliveryStatus === "failed") && (
                        <IconButton
                            className="message-delivery-button"
                            color="error"
                            size="small"
                            title="Retry message."
                            onClick={() => {
                                onRetryButtonClicked();
                            }}
                            disabled={sendMessageApiStatus === "inprogress"}
                        >
                            <Refresh />
                        </IconButton>
                    )
                }
                {
                    (sender === "user" && deliveryStatus === "delivered") && (
                        <IconButton
                            className="message-delivery-button"
                            color="success"
                            size="small"
                            title="Message delivered.">
                            <DoneAll />
                        </IconButton>
                    )
                } */}
                {sender === "user" && (
                    <>
                        <div className="user-text" ref={textRef}>
                            {Parser(
                                sanitizeHtml(
                                    forHistory === "history"
                                        ? text
                                        : modifiedText,
                                ),
                            )}
                        </div>
                        <div className="image">
                            <img
                                src={imageUrl}
                                alt="ub"
                                className="image-user"
                            />
                        </div>
                    </>
                )}
                {sender === "assistant" && (
                    <>
                        <div className="image">
                            <img src={imageUrl} alt="ub" className="image" />
                        </div>
                        <div className="answer-block">
                            <div className="text-t" ref={textRef}>
                                {sender === "assistant" ? (
                                    <ReactMarkdown>
                                        {forHistory === "history"
                                            ? text
                                            : modifiedText}
                                    </ReactMarkdown>
                                ) : (
                                    Parser(
                                        sanitizeHtml(
                                            forHistory === "history"
                                                ? text
                                                : modifiedText,
                                        ),
                                    )
                                )}
                            </div>
                            <div
                                className={
                                    sender === "assistant"
                                        ? "actions"
                                        : "hidden actions"
                                }
                            >
                                {sender === "assistant" && (
                                    <>
                                        <IconButton
                                            className="button"
                                            title="Good Answer"
                                            disabled={
                                                messageFeedbackApiStatus ===
                                                "inprogress"
                                            }
                                            onClick={() => {
                                                onFeedbackButtonClicked(
                                                    messageId,
                                                    "TU",
                                                    null,
                                                    [],
                                                );
                                                setFeedbackSelected("TU");
                                            }}
                                            onMouseEnter={() =>
                                                setIsHoveredTU(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredTU(false)
                                            }
                                            style={{
                                                color:
                                                    feedbackSelected === "TU"
                                                        ? "#00490A"
                                                        : isHoveredTU
                                                            ? "#696969"
                                                            : "silver",
                                                transition: "color 0.3s",
                                            }}
                                        >
                                            <ThumbUpAltOutlined />
                                        </IconButton>
                                        <IconButton
                                            className="button"
                                            title="Incorrect Answer"
                                            disabled={
                                                messageFeedbackApiStatus ===
                                                "inprogress"
                                            }
                                            onClick={() => {
                                                onThumbsDownButtonClicked(
                                                    messageId,
                                                );
                                                setFeedbackSelected("TD");
                                            }}
                                            onMouseEnter={() =>
                                                setIsHoveredTD(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredTD(false)
                                            }
                                            style={{
                                                color:
                                                    feedbackAction === "TD"
                                                        ? "#c80815"
                                                        : isHoveredTD
                                                            ? "#696969"
                                                            : "silver",
                                                transition: "color 0.3s",
                                            }}
                                        >
                                            <ThumbDownAltOutlined />
                                        </IconButton>
                                        <IconButton
                                            className="button"
                                            title="Copy Message"
                                            onClick={copyToClipboard}
                                            onMouseEnter={() =>
                                                setIsHoveredCopy(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredCopy(false)
                                            }
                                            style={{
                                                color: isHoveredCopy
                                                    ? "#696969"
                                                    : "silver",
                                                transition: "color 0.3s",
                                            }}
                                        >
                                            <ContentCopyOutlinedIcon />
                                        </IconButton>
                                        <Snackbar
                                            open={isSnackbarOpen}
                                            autoHideDuration={3000}
                                            onClose={handleSnackbarClose}
                                            message="Copied!"
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                            sx={{
                                                marginTop: "64px",
                                                width: "100%",
                                            }}
                                        />
                                        <IconButton
                                            className="button"
                                            title="Share Content"
                                            onClick={openDialog}
                                            onMouseEnter={() =>
                                                setIsHoveredShare(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredShare(false)
                                            }
                                            style={{
                                                color: isHoveredShare
                                                    ? "#696969"
                                                    : "silver",
                                                transition: "color 0.3s",
                                            }}
                                        >
                                            <ReplyIcon className="mirrored-icon" />
                                        </IconButton>
                                        <Dialog open={isOpen} onClose={closeDialog}>
                                            <div className="share-dialog">
                                                <DialogTitle style={{ fontSize: "20px", color: "white", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#00490A", padding: "10px" }}>
                                                    Share this via
                                                    <CancelOutlinedIcon style={window.innerWidth <= 480 ? { position: 'absolute', top: '3px', right: '3px', cursor: 'pointer', fontSize: "15px" } : { position: 'absolute', top: '5px', right: '5px', cursor: 'pointer', fontSize: "20px" }} onClick={closeDialog} />
                                                </DialogTitle>
                                                <DialogContent style={{ fontSize: "17.5px", padding: "6px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    {/*<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                                        <IconButton onClick={handleLinkedInClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                            <LinkedinIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                        </IconButton>
                                                        <ShareTextOnTwitter text={twitterText} />
                                                        <IconButton onClick={handleFacebookClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                            <FacebookIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                        </IconButton>
                                                        <IconButton onClick={handleWhatsAppClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                            <WhatsappIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                        </IconButton>
                                                        <IconButton onClick={handleEmailClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                                                            <EmailIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
                                                        </IconButton>
                                                        <Snackbar
                                                            open={snackbarOpenShare}
                                                            autoHideDuration={5000}
                                                            onClose={handleShareSnackbarClose}
                                                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                                            message={`Just paste the conversation on the ${redirectMessage} post and share. Redirecting in ${secondsLeft} seconds...`}
                                                        />
                                                    </div> */}
                                                    <ShareIcons
                                                        handleLinkedInClick={handleLinkedInClick}
                                                        handleFacebookClick={handleFacebookClick}
                                                        handleWhatsAppClick={handleWhatsAppClick}
                                                        handleEmailClick={handleEmailClick}
                                                        snackbarOpenShare={snackbarOpenShare}
                                                        handleShareSnackbarClose={handleShareSnackbarClose}
                                                        redirectMessage={redirectMessage}
                                                        secondsLeft={secondsLeft}
                                                        twitterText={twitterText}
                                                    />
                                                </DialogContent>
                                            </div>
                                        </Dialog>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TextMessage;
