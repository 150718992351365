import { TwitterShareButton, TwitterIcon, LinkedinIcon, FacebookIcon, WhatsappIcon, EmailIcon } from 'react-share';
import { IconButton, Snackbar } from '@mui/material';
import X_logo from "../../../../assets/images/X_logo.png"

export const ShareTextOnTwitter = ({ text }) => {
    const url = "https://marshallgoldsmith.ai/";

    return (
        <TwitterShareButton url={url} title={text}>
            <img src={X_logo} alt="Twiter" style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px" }} />
        </TwitterShareButton>
    );
};


export const ShareIcons = ({ handleLinkedInClick, handleFacebookClick, handleWhatsAppClick, handleEmailClick, snackbarOpenShare, handleShareSnackbarClose, redirectMessage, secondsLeft, twitterText }) => {
    const isMobile = () => window.innerWidth <= 480;

    return (
        <>
            {isMobile() ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
                    <IconButton onClick={handleFacebookClick} style={{ paddingBottom: "10px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "5px", transform: "translateY(-2px)" }}>
                        <FacebookIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                    </IconButton>
                    <ShareTextOnTwitter text={twitterText} />
                    <IconButton onClick={handleWhatsAppClick} style={{ paddingBottom: "5px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "10px", transform: "translateY(-2px)" }}>
                        <WhatsappIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                    </IconButton>
                    <IconButton onClick={handleLinkedInClick} style={{ padding: "5px", transform: "translateY(-2px)" }}>
                        <LinkedinIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                    </IconButton>
                    <IconButton onClick={handleEmailClick} style={{ padding: "5px", transform: "translateY(-2px)" }}>
                        <EmailIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                    </IconButton>
                    <Snackbar
                        open={snackbarOpenShare}
                        autoHideDuration={5000}
                        onClose={handleShareSnackbarClose}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        message={`Just paste the conversation on the ${redirectMessage} post and share. Redirecting in ${secondsLeft} seconds...`}
                    />
                </div>
            ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "10px" }}>
                        <IconButton onClick={handleFacebookClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                            <FacebookIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                        </IconButton>
                        <ShareTextOnTwitter text={twitterText} />
                        <IconButton onClick={handleWhatsAppClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                            <WhatsappIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "10px" }}>
                        <IconButton onClick={handleLinkedInClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                            <LinkedinIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                        </IconButton>
                        <IconButton onClick={handleEmailClick} style={{ padding: "0px", transform: "translateY(-2px)" }}>
                            <EmailIcon style={{ height: "50px", width: "50px", marginRight: "8px", marginLeft: "8px", borderRadius: "50%" }} />
                        </IconButton>
                    </div>
                    <Snackbar
                        open={snackbarOpenShare}
                        autoHideDuration={5000}
                        onClose={handleShareSnackbarClose}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        message={`Just paste the conversation on the ${redirectMessage} post and share. Redirecting in ${secondsLeft} seconds...`}
                    />
                </div>
            )
            }
        </>
    );
};




