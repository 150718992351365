import React, { useState } from 'react';
import './Footer.index.less';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import ContactUsForm from '../landingPage/ContactUsForm/ContactUsForm.index';
// import ContactUsForm from '../ContactUsForm/ContactUsForm.index';

const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleContactClick = () => {
    setOpen(true); // Open the modal when Contact Us is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
  };

  const handleCookieClick = () => {
    window.open("/cookie", "_blank");
  };

  const handleTermsClick = () => {
    window.open("/t&c", "_blank");
  };

  const handlePrivacyClick = () => {
    window.open("/privacy", "_blank");
  };

  return (
    <div className="footer">
      <div>
        All rights reserved © 2024 Fractal Analytics Inc..
      </div>
      <div className="privacy-policy-container">
        <div onClick={handleContactClick}>Contact Us</div>
        <div className="vertical-line"></div>
        <div onClick={handleCookieClick}>Cookies</div>
        <div className="vertical-line"></div>
        <div onClick={handlePrivacyClick}>Privacy Policy</div>
        <div className="vertical-line"></div>
        <div onClick={handleTermsClick}>Terms of Use</div>
      </div>

      {/* Modal Dialog for Contact Us */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <ContactUsForm onClose={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Footer;
