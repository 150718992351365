import React, {useRef, useState, useEffect} from "react";
import userImageUrl from "../../../../assets/images/user.png";
import botImageUrl from "../../../../assets/images/mg-face-crop.jpeg";
import Parser from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { IconButton, Snackbar } from "@mui/material";
import { DoneAll, Refresh, ThumbDownAltOutlined, ThumbUpAltOutlined } from "@mui/icons-material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Typewriter from "typewriter-effect";
import copy from "copy-to-clipboard";

interface Props {
    messageId: string,
    text: string,
    sender: string,
    onFeedbackButtonClicked: (messageId: string, action: "TU" | "TD", feedbackText: string | null, checkboxResponses: string[]) => void,
    sendMessageApiStatus: "inprogress" | "complete" | "failed" | null,
    messageFeedbackApiStatus: "inprogress" | "complete" | "failed" | null,
    onThumbsDownButtonClicked: (messageId: string) => void
    onTextContainerResized: () => void,
    onRetryButtonClicked: () => void,
    feedbackAction?: "TU" | "TD" | null,
    deliveryStatus?: "delivered" | "failed"
}

const VideoMessage: React.FC<Props> = ({
    messageId,
    text,
    sender,
    onFeedbackButtonClicked,
    sendMessageApiStatus,
    messageFeedbackApiStatus,
    onThumbsDownButtonClicked,
    feedbackAction = null,
    onTextContainerResized,
    onRetryButtonClicked,
    deliveryStatus
}) => {

    let className = "sent message";
    let imageUrl = userImageUrl;
    const textRef = useRef<HTMLDivElement>();

    if (sender === "assistant") {
        className = "received message";
        imageUrl = botImageUrl;
    }

    useEffect(() => {
        let resizeObserver = new ResizeObserver(() => {
            onTextContainerResized();
        });
        resizeObserver.observe(textRef.current);
        // eslint-disable-next-line
    }, []);

    const [isHoveredTU, setIsHoveredTU] = useState(false);
    const [isHoveredTD, setIsHoveredTD] = useState(false);
    const [isHoveredCopy, setIsHoveredCopy] = useState(false);
    const [feedbackSelected, setFeedbackSelected] = useState(null);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    const copyToClipboard = () => {
        copy(text);
        setIsSnackbarOpen(true);
    }

    return (
        <div className={className}>
            <div className={sender === "user" ? "top-user" : "top"}>
                {/* {
                    (sender === "user" && deliveryStatus === "failed") && (
                        <IconButton
                            className="message-delivery-button"
                            color="error"
                            size="small"
                            title="Retry message."
                            onClick={() => {
                                onRetryButtonClicked();
                            }}
                            disabled={sendMessageApiStatus === "inprogress"}
                        >
                            <Refresh />
                        </IconButton>
                    )
                }
                {
                    (sender === "user" && deliveryStatus === "delivered") && (
                        <IconButton
                            className="message-delivery-button"
                            color="success"
                            size="small"
                            title="Message delivered.">
                            <DoneAll />
                        </IconButton>
                    )
                } */}
                {sender === "user" && (
                    <>
                        <div className="user-text" ref={textRef}>
                            {
                                Parser(sanitizeHtml(text))
                            }
                        </div>
                        <div className="image">
                            <img src={imageUrl} alt="ub" className="image-user" />
                        </div>
                    </>
                )}
                {sender === "assistant" && (
                    <>
                        <div className="image">
                            <img src={imageUrl} alt="ub" className="image" />
                        </div>
                        <div className="answer-block">
                            <div className="text-t" ref={textRef}>
                                {sender === "assistant" ? (
                                    <Typewriter
                                        options={{
                                            cursor: "",
                                            delay: 15,
                                        }}
                                        onInit={(typewriter) => {
                                            typewriter.typeString(sanitizeHtml(text)).start()
                                        }}
                                    />
                                ) : (
                                    Parser(sanitizeHtml(text))
                                )}
                            </div>
                            <div className={sender === "assistant" ? "actions" : "hidden actions"}>
                                {
                                    sender === "assistant" && (
                                        <>
                                            <IconButton
                                                className="button"
                                                title="Good Answer"
                                                disabled={messageFeedbackApiStatus === "inprogress"}
                                                onClick={() => {
                                                    onFeedbackButtonClicked(messageId, "TU", null, []);
                                                    setFeedbackSelected("TU");
                                                }}
                                                onMouseEnter={() => setIsHoveredTU(true)}
                                                onMouseLeave={() => setIsHoveredTU(false)}
                                                style={{
                                                    color: feedbackSelected === 'TU' ? '#00490A' : isHoveredTU ? '#696969' : 'silver',
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ThumbUpAltOutlined />
                                            </IconButton>
                                            <IconButton
                                                className="button"
                                                title="Incorrect Answer"
                                                disabled={messageFeedbackApiStatus === "inprogress"}
                                                onClick={() => {
                                                    onThumbsDownButtonClicked(messageId);
                                                    setFeedbackSelected("TD");
                                                }}
                                                onMouseEnter={() => setIsHoveredTD(true)}
                                                onMouseLeave={() => setIsHoveredTD(false)}
                                                style={{
                                                    color: feedbackAction === 'TD' ? '#c80815' : isHoveredTD ? '#696969' : 'silver',
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ThumbDownAltOutlined />
                                            </IconButton>
                                            <IconButton
                                                className="button"
                                                title="Copy Message"
                                                onClick={copyToClipboard}
                                                onMouseEnter={() => setIsHoveredCopy(true)}
                                                onMouseLeave={() => setIsHoveredCopy(false)}
                                                style={{
                                                    color: isHoveredCopy ? '#696969' : 'silver',
                                                    transition: "color 0.3s",
                                                }}
                                            >
                                                <ContentCopyOutlinedIcon />
                                            </IconButton>
                                            <Snackbar
                                                open={isSnackbarOpen}
                                                autoHideDuration={3000}
                                                onClose={handleSnackbarClose}
                                                message="Copied!"
                                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                                sx={{ marginTop: "64px", width: "100%" }}
                                            />
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )

}

export default VideoMessage;
