import { SET_REGISTRATION_STATUS } from "./register.actions"
import { RegisterStateReducerModel } from "./register.models"

const initialState: RegisterStateReducerModel = {
    registrationStatus: null
}

export const RegisterReducer = (state: RegisterStateReducerModel = initialState, action: { type: string, payload: Record<any, any> }) => {
    
    switch (action.type) {
        case SET_REGISTRATION_STATUS:
            return {
                ...state,
                ...action.payload
            };
        
        default:
            return state;
    }
}