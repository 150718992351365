import Axios from "../globals/axios/axios.index";

export const DeleteSessionService = {

    deleteSession: (sessionId: string) => {
        const authToken = localStorage.getItem("authenticationToken");

        return Axios.delete(`/api/v1/chat/delete-session/${sessionId}/`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
        });
    }
};