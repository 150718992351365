import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Collapse, Typography, InputAdornment, Button, TextField, IconButton, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UpdatePasswordService } from "../../services/updatePasswordService";
import mgLogoImageUrl from "../../assets/images/mg-logo.png";
import fractalLogo from "../../assets/images/fractal-logo.png";
import "./updatePassword.index.less"

const UpdatePasswordPage = () => {

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [updateStatus, setUpdateStatus] = useState("");
    const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
    const [error, setError] = useState("")

    const navigate = useNavigate()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const flow = queryParams.get("flow");
    const otp = queryParams.get("otp");

    const handleSetNewPassword = async () => {
        if (newPassword !== confirmPassword) {
            setUpdateStatus("Passwords do not match.");
            setUpdateStatusOpen(true);
            setSuccessMsg(false)
            setTimeout(() => {
                setUpdateStatusOpen(false); // Hide status message
            }, 3000);
            return;
        }
        try {
            const updateResponse = await UpdatePasswordService.updatePassword(email, newPassword, otp);
            if (updateResponse.status === 200) {
                setUpdateStatus("Password has been updated successfully.");
                setUpdateStatusOpen(true);
                setSuccessMsg(true)
                setTimeout(() => {
                    navigate("/login");
                }, 500);
            } else {
                setUpdateStatus("Password updation failed.");
                setUpdateStatusOpen(true);
                setSuccessMsg(false)
                setTimeout(() => {
                    setUpdateStatusOpen(true); // Hide status message
                }, 3000);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                // const errorMessage = "Password updation failed. " + error.response.data.errors + ".";
                const errorMessages = error.response.data.errors;
                const errorMessage = "Password updation failed. \n" +
                    errorMessages.map((errorMsg) => `${errorMsg}.`).join("\n");
                setUpdateStatus(errorMessage);
                setUpdateStatusOpen(true);
                setSuccessMsg(false);
            } else {
                setUpdateStatus("An error occurred during password updation.");
                setUpdateStatusOpen(true);
                setSuccessMsg(false);
            }
            setTimeout(() => {
                setUpdateStatusOpen(false); // Hide status message
            }, 3000);
        }
    }

    useEffect(() => {
        document.title = "Password Update";
    }, []);

    return (
        <div className="update">
            <div className="maincontainer">
                <div>
                    <img className="logoImage" src={mgLogoImageUrl} alt="Logo" />
                </div>
                <h2 className="maintitle">Reset Password</h2>
                <div className="form-field">
                    <Typography style={{ paddingTop: "10px" }}>Password</Typography>
                    <TextField
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="form-field">
                    <Typography style={{ paddingTop: "10px" }}>Confirm Password</Typography>
                    <TextField
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="button-container">
                    <span className="button-span">
                        <Button variant="contained" color="primary" style={{ textTransform: "none" }} onClick={handleSetNewPassword}>
                            Continue
                        </Button>
                    </span>
                </div>
                <div>
                    <Collapse orientation="vertical" in={updateStatusOpen}>
                        <Typography className={`status ${successMsg ? "success" : "error"}`}>{updateStatus}</Typography>
                    </Collapse>
                </div>
            </div>
            <div className="logo-container">
                <span className="powered-by-text">Powered by</span>
                <img src={fractalLogo} alt="Logo" className="logo-image" />
            </div>
        </div>
    )

}

export default UpdatePasswordPage;