import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../pages/layout/layout.index";
import PageNotFound from "../pages/pageNotFound/pageNotFound.index";
import ProtectedRoute from "../globals/components/protectedRoute/protectedRoute.index";
import ErrorBoundary from "../globals/components/errorboundary/errorboundary.index";
import QAndA from "../pages/qAndA/qAndA.index";
import Login from "../pages/login/login.index";
import { Feedback } from "../pages/feedback/feedback.index";
import Register from "../pages/register/register.index";
import VerifyOtpPage from "../pages/verifyotp/verifyotp.index";
import CheckEmailPage from "../pages/checkEmail/checkEmail.index";
import UpdatePasswordPage from "../pages/updatePassword/updatePassword.index";
import ChatV2 from "../pages/chatV2/chat.index";
import TermsOfUse from "../pages/t&cPage/t&cPage.index";
import PrivacyPolicy from "../pages/t&cPage/privacyPolicy.index";
import LandingPage from "../pages/landingPage/landingPage.index";
import Cookie from "../pages/cookie/cookie.index";
import ConversationSharing from "../pages/chatV2/conversationSharing/conversationSharing.index";
import ChatHistory from "../pages/chatV2/components/message/chathistory.index";
const AppRouter = () => (
    <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={
                    <LandingPage />
                } />
            <Route
                path="/login"
                element={
                    <Login />
                } />
            <Route
                path="/register"
                element={
                    <Register />
                } />
            <Route
                path="/verify-otp"
                element={
                    <VerifyOtpPage />
                }
            />
            <Route
                path="/verify-email"
                element={
                    <CheckEmailPage />
                }
            />
            <Route
                path="/update-password"
                element={
                    <UpdatePasswordPage />
                }
            />
            <Route
                path="/cookie"
                element={
                    <Cookie />
                }
            />
            <Route
                path="/t&c"
                element={
                    <TermsOfUse />
                }
            />
            <Route
                path="/privacy"
                element={
                    <PrivacyPolicy />
                }
            />
            <Route
                path="/get_shared_chat_messages/:sessionId/:shareId"
                element={
                    <ConversationSharing />
                }
            />
            <Route element={<Layout />}>
                <Route
                    path="/feedback"
                    element={
                        <ProtectedRoute path="/chat">
                            <ErrorBoundary key="/chat">
                                <Feedback />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } />
                <Route
                    path="/q-and-a"
                    element={
                        <ProtectedRoute path="/chat">
                            <ErrorBoundary key="/chat">
                                <QAndA />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } />
                {/* <Route
                    path="/v2/q-and-a"
                    element={
                        <ProtectedRoute path="/v2">
                            <ErrorBoundary key="/v2">
                                <QAndA />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } /> */}
                <Route
                    path="/old/q-and-a"
                    element={
                        <ProtectedRoute path="/old">
                            <ErrorBoundary key="/old">
                                <QAndA />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } />
                {/* <Route
                    path="/v2/ws"
                    element={
                        <ProtectedRoute path="/v2/ws">
                            <ErrorBoundary key="/v2/ws">
                                <ChatV2WS />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } /> */}
                {/* <Route
                    path="/v2"
                    element={
                        <ProtectedRoute path="/v2">
                            <ErrorBoundary key="/v2">
                                <ChatV2 />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } /> */}
                {/* <Route
                    path="/old"
                    element={
                        <ProtectedRoute path="/old">
                            <ErrorBoundary key="/old">
                                <Chat />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } /> */}
                <Route
                    path="/chat"
                    element={
                        <ProtectedRoute path="/chat">
                            <ErrorBoundary key="/chat">
                                {/* <Chat /> */}
                                <ChatV2 />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } />
                <Route path="*" element={<PageNotFound />} />
                <Route
                    path="/chathistory"
                    element={
                        <ProtectedRoute path="/chathistory">
                            <ErrorBoundary key="/chathistory">
                               <ChatHistory />
                            </ErrorBoundary>
                        </ProtectedRoute>
                    } />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default AppRouter;
