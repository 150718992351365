import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { CheckEmailService } from '../../services/checkEmailService';
import { Collapse, Typography, IconButton, TextField, CircularProgress } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import mgLogoImageUrl from "../../assets/images/mg-logo.png";
import fractalLogo from "../../assets/images/fractal-logo.png";
import "./checkEmail.index.less";

const CheckEmailPage = () => {
    const [email, setEmail] = useState("");
    const [verificationStatus, setVerificationStatus] = useState("");
    const [verificationStatusOpen, setVerificationStatusOpen] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Verify Email";
    }, []);

    const checkEmail = async () => {

        if ("gtag" in window) {
            (window as any).gtag("event", "click", {
                event_category: "custom",
                event_label: "check_email_button_clicked",
                value: "check_email_button_clicked"
            }, { "debug_mode": true });
        }

        setLoading(true);
        setButtonClicked(true);
        if (!email) {
            setVerificationStatus("Please provide an email id.")
            setVerificationStatusOpen(true)
            setSuccessMsg(false)
            setTimeout(() => {
                setVerificationStatusOpen(false); // Hide status message
            }, 3000);
            return;
        }

        try {
            const checkResponse = await CheckEmailService.checkEmail(email);
            if (checkResponse.status === 200) {
                setVerificationStatus("Email verification successful.");
                setVerificationStatusOpen(true);
                setSuccessMsg(true)
                // setTimeout(() => {
                //     navigate(`/verify-otp?email=${email}&flow=forgot-password`);
                // }, 500);
                setTimeout(() => {
                    navigate(`/login`);
                }, 500);
            } else {
                setVerificationStatus("Email verification failed.");
                setVerificationStatusOpen(true);
                setSuccessMsg(false)
                setTimeout(() => {
                    setVerificationStatusOpen(false); // Hide status message
                }, 3000);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                const errorMessage = "Email verification failed. " + error.response.data.error + ".";
                setVerificationStatus(errorMessage);
                setVerificationStatusOpen(true);
                setSuccessMsg(false);
            } else {
                setVerificationStatus("An error occurred during email verification.");
                setVerificationStatusOpen(true);
                setSuccessMsg(false);
            }
            setTimeout(() => {
                setVerificationStatusOpen(false); // Hide status message
            }, 3000);
        }
        finally {
            setLoading(false);
        }
        setTimeout(() => {
            setButtonClicked(false);
        }, 300);
    };

    return (
        <div className="check">
            <div className="maincontainer">
                <div className="logoImageContainer">
                    <img style={{ width: "100px" }} src={mgLogoImageUrl} alt="Logo" />
                </div>
                <Typography className="maintitle">Registered Email</Typography>
                <div className="container">
                    <span className="form-field">
                        <TextField
                            type="text"
                            placeholder="eg. john.doe@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* <TextField
                            error={verificationStatusOpen && !successMsg}
                            type="text"
                            placeholder="eg. john.doe@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            helperText={verificationStatusOpen && !successMsg ? verificationStatus : ""}
                        /> */}
                        <span className="button-container">
                            <span className={`icon-span ${buttonClicked ? "clicked" : ""}`}>
                                <IconButton
                                    color="primary"
                                    title="Next"
                                    disabled={loading}
                                    onClick={checkEmail}
                                >
                                    {loading ? <CircularProgress size={48} /> : <ArrowCircleRightIcon className="large-button" />}
                                </IconButton>
                            </span>
                        </span>
                    </span>
                    {/* <span className="button-container">
                        <span className="icon-span">
                            <IconButton
                                color="primary"
                                title="Next"
                                onClick={checkEmail}
                            >
                                <ArrowCircleRightIcon className="large-button" />
                            </IconButton>
                        </span>
                    </span> */}
                </div>
                {/* <Typography style={{ fontSize: "12px" }}>You will receive a One Time Password on the provided registered email. Keep it handy for next step.</Typography> */}
                <Typography style={{ fontSize: "12px" }}>A password update link will be sent to the provided registered email id. Please click on the link to update your password.</Typography>
                <div>
                    <Collapse orientation="vertical" in={verificationStatusOpen}>
                        <Typography className={`status ${successMsg ? "success" : "error"}`}>{verificationStatus}</Typography>
                    </Collapse>
                </div>
            </div>
            <div className="logo-container">
                <span className="powered-by-text">Powered by</span>
                <img src={fractalLogo} alt="Logo" className="logo-image" />
            </div>
        </div>
    );

}

export default CheckEmailPage;

