import React from "react";
import "./cookie.index.less"
import fractalLogo from "../../assets/images/fractal-logo.png";
import { Paper, Typography, Link } from "@mui/material";

const Cookie = () => {
    
    return (
        <Paper elevation={3} className="paperContainer">
        <div className="cookieContainer">
            <div className="header">
                <img src={fractalLogo} alt="Logo" className="logo" />
                <hr className="line" />
            </div>
            <div>
                <h3>Cookie Box</h3>
            </div>
        
            <div>
                <p className="para-text">
                We use cookies to give you the best possible experience. Some are essential for this site to function; others help us understand how you use the site, so we can improve it. We may also use cookies for targeting purposes. Click “Accept all cookies” to proceed as specified, or click “Manage my preferences” to choose the types of cookies you will accept.
                </p><br></br>
                <p className="large-text">Accept All Cookies</p>
                <p className="large-text">Manage my preferences</p>
            </div><br></br>

            <div>
                <h3>Cookie Policy</h3>
            </div>
            <div>
                <h3 style={{ fontStyle: 'italic' }}>Effective date : 29/2/2024</h3>
            </div>
                
            <p className="para-text">
             Welcome to marshallgoldsmith.ai! We understand that your privacy is important to you and are committed to being transparent about the technologies we use. This Cookie Policy explains how and why cookies and other similar technologies (collectively “Cookies”) may be stored on and accessed from your device when you use or visit any website or app that posts a link to this Policy (collectively, “the Sites”). This Cookie Policy should be read together with our <strong><u><a href="/privacy" className="highlighted-link">Privacy Policy</a></u></strong> and our <strong><u><a href="/t&c" className="highlighted-link">Terms of Use</a></u></strong>.
            </p>


           
                <h3>Welcome to marshallgoldsmith.ai!</h3>
                <p className="para-text">We understand that your privacy is important to you and are committed to being transparent about the technologies we use. This Cookie Policy explains how and why cookies and other similar technologies (collectively “Cookies”) may be stored on and accessed from your device when you use or visit any website or app that posts a link to this Policy (collectively, “the Sites”). This Cookie Policy should be read together with our Privacy Policy and our Terms of use.</p>
            <div className="ul">
                <h3>1. What are Cookies?</h3>
                <p className="para-text">A cookie is a small text file that can be stored on and accessed from your device when you visit our Sites, to the extent you agree. The other tracking technologies work similarly to cookies and place small data files on your devices or monitor your website activity to enable us to collect information about how you use our Sites. This allows our Sites to recognize your device from those of other users of the Sites. The information provided below about cookies also applies to these other tracking technologies.</p>
                
                <h3>2. Types of Cookies We Use</h3>
                <div className="ul-inside">
                <h4 className="section-heading">a. Necessary Cookies:</h4>
                <p className="para-text">These Cookies are necessary to allow us to operate our Sites so you may access them as you have requested. These Cookies, for example, let us recognize that you have created an account and have logged into that account to access Site content. They also include Cookies that enable us to remember your previous actions within the same browsing session and secure our Sites.</p>
                
                <h4 className="section-heading">b. Analytical/Performance Cookies:</h4>
                <p className="para-text">These Cookies are used by us or third party service providers to analyze how the Sites are used and how they are performing. For example, these Cookies track what pages are most frequently visited, and from what locations our visitors come from. We use Google Analytics to collect information about how visitors use our website. These cookies help us analyze and improve the performance of our site. All data collected is anonymized and aggregated.</p>
                
                <h4 className="section-heading">c. Functionality Cookies:</h4>
                <p className="para-text">These Cookies let us operate the Sites in accordance with the choices you make. These Cookies permit us to "remember" you in-between visits. For instance, we will recognize your user name and remember how you customized the Sites and services, for example by adjusting text size, fonts, languages and other parts of web pages that are alterable, and provide you with the same customizations during future visits.</p>
                
                <h4 className="section-heading">d. Advertising cookies</h4>
                <p className="para-text">These cookies are used to deliver advertisements that are relevant to you and to your interests. In addition, they are used to limit the number of times you see an advertisement. They are usually placed on the website by advertising networks with the website operator’s permission. These cookies remember that you have visited a website and this information is shared with other organizations such as advertisers. Often targeting or advertising cookies will be linked to site functionality provided by the other organization. The advertising networks have their own privacy policies, and may use their Cookies to target advertising to you on other websites, based on your visit to our Sites.</p>
                </div>
                <h3>3. How We Use Cookies</h3>
                <p className="para-text">We use Cookies and Other Tracking Technologies to identify you and your interests, to remember your preferences, and to track your use of our Sites. We also use Cookies and Other Tracking Technologies to control access to certain content on our Sites, protect the Sites, and to process any requests that you make of us. Our cookies store randomly assigned user identification numbers, pages visited by a visitor, time spent on a page, clicks, demographic information about the visitor such as country, IP address, device and operating system used by the visitor. We also use cookies for distinguishing users and user sessions and for identifying new users and sessions, to determine the traffic source that explains how the user reached our site and to throttle user request rate in order to limit the number of data requests in a time frame.</p>
                
                <h3>4. Cookie Management</h3>
                <p className="para-text">If you do not want Cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some Cookies. Please note that if you use your browser settings to block all Cookies (including strictly Necessary Cookies) you may not be able to access or use all or parts or functionalities of our Site. When you arrive on our website a pop-up message will appear asking for your consent to place cookies on your device. In order to provide your consent, please go through the details of cookies used by our website for different purposes. You can set your preferences accordingly and click ‘OK. Once your consent has been provided, this message will not appear again when you revisit. If you, or another user of your device, wish to withdraw your consent at any time, you can do so by opting out as per your choice by clicking <a href="#">here</a>.</p>
                
                <h3>5. Updates to Cookie Policy</h3>
                <p className="para-text">We may update this Cookie Policy to reflect changes in our practices or for legal reasons. Please check this page regularly for any updates.</p>
                
                <h3>6. Contact Us</h3>
<p className="para-text">If you have any questions or concerns about our use of cookies, please contact us at <a href="mailto:MarshallBot@fractal.ai">MarshallBot@fractal.ai</a>.</p>

                            </div>
            <div><p className="para-text">By continuing to use our website, you consent to the use of cookies as outlined in this policy.</p></div>
            </div>
        </Paper>
        
    )
   
}

export default Cookie