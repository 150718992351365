import React, {useEffect, useState} from "react";
import { BlockOutlined, Cancel, CheckCircle, SentimentDissatisfiedRounded, ThumbDownAltRounded, ThumbUpAltRounded } from "@mui/icons-material";
import { Paper, Button, CircularProgress, FormControl, IconButton, Modal, TextField, Typography } from "@mui/material";
import * as QAndAThunks from "./qAndA.thunks";
import "./qAndA.index.less";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../globals/redux/store/store.index";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import * as GlobalThunks from "../layout/layout.thunks";
import Parser from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import genesisLogoUrl from "../../assets/images/genesis-logo.png";

const QAndA = () => {

    // Selectors
    const qAndAState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.qAndAReducer,
    );

    const [questionText, setQuestionText] = useState<string | null>(null);
    const [answerText, setAnswerText] = useState<string | null>(null);
    const [userRating, setUserRating] = useState<"thumbs-up" | "thumbs-down" | "block" | null>(null);
    const [askApiStatus, setAskApiStatus] = useState<string | null>(null);
    const [feedbackCodes, setFeedbackCodes] = useState<Record<any, any>[]>([]);
    const [askApiInProgress, setAskApiInProgress] = useState<boolean>(false);

    const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
    const [progressModalProps, setProgressModalProps] = useState<Record<any, any>>({
        icon: <CircularProgress />,
        text: "Saving your response..."
    });
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const FEEDBACK_CODE_BLOCK = "a_bq";
    const FEEDBACK_CODE_POSITIVE = "a_pos";
    const FEEDBACK_CODE_NEGATIVE = "a_neg";

    useEffect(() => {
        document.title = "Q and A"
        dispatch(QAndAThunks.fetchFeedbackCodes());
        // eslint-disable-next-line
    }, []);

    // Manage ask api call and it's response [answer] related state
    useEffect(() => {
        if (qAndAState.askApiCallStatus === "successful") {
            setAskApiStatus("successful");
            setAskApiInProgress(false);

            switch (qAndAState.questionAnswer.answer_code.code) {
                case "na_iq":
                    dispatch(GlobalThunks.setNotificationMessage(true, "Question is invalid.", "error"));
                    dispatch(QAndAThunks.resetAskApiCallStatus());
                    break;
                
                case "na_api_err":
                    dispatch(GlobalThunks.setNotificationMessage(true, `API Error: ${qAndAState.questionAnswer.error}`, "error"));
                    startOver();
                    break;
                
                default:
                    setAnswerText(qAndAState.questionAnswer.answer);
                    break;

            }
            
         }
        else if (qAndAState.askApiCallStatus === "failed") {
            setAnswerText(null);
            setAskApiStatus("failed");
            setAskApiInProgress(false);
            dispatch(QAndAThunks.resetAskApiCallStatus());
        }
        else {
            setAnswerText(null);
            setAskApiStatus(null);
            setAskApiInProgress(false);
        }
    }, [qAndAState.askApiCallStatus]);

    // Manage feedback code api call
    useEffect(() => {
        if (qAndAState.feedbackCodeApiCallStatus === "successful") {
            setFeedbackCodes(qAndAState.feedbackCodes);
        }
        else if (qAndAState.feedbackCodeApiCallStatus === "failed") {
            setFeedbackCodes([]);
            dispatch(GlobalThunks.setNotificationMessage(true, "Feedback codes api call failed.", "error"));
        }
    }, [qAndAState.feedbackCodeApiCallStatus]);

    // Manage user feedback response
    useEffect(() => {
        if (qAndAState.userFeedbackApiCallStatus === "successful") {
            setProgressModalProps({
                icon: <CheckCircle className="success" />,
                text: "Your feedback has been saved."
            });
            setTimeout(() => {
                startOver();
                setShowProgressModal(false);
            }, 2500);
        }
        else if (qAndAState.userFeedbackApiCallStatus === "failed") {
            setProgressModalProps({
                icon: <Cancel className="failure" />,
                text: "Your feedback could not be saved. Try again."
            });
            setTimeout(() => {
                setShowProgressModal(false);
            }, 2500);
        }
        else {
            setProgressModalProps({
                icon: <CircularProgress className="progress" />,
                text: "Saving your feedback..."
            });
        }
    }, [qAndAState.userFeedbackApiCallStatus]);

    useEffect(() => {
        let feedbackCodeAction = null;

        switch (userRating) {
            case "thumbs-up":
                feedbackCodeAction = feedbackCodes.filter((item: Record<any, any>) => item.code === FEEDBACK_CODE_POSITIVE );
                break;
            case "thumbs-down":
                feedbackCodeAction = feedbackCodes.filter((item: Record<any, any>) => item.code === FEEDBACK_CODE_NEGATIVE);
                break;
            case "block":
                feedbackCodeAction = feedbackCodes.filter((item: Record<any, any>) => item.code === FEEDBACK_CODE_BLOCK);
                break;
        }

        if (feedbackCodeAction !== null) {
            feedbackCodeAction = feedbackCodeAction[0];
            dispatch(QAndAThunks.setSelectedUserFeedbackAction(feedbackCodeAction));

            if (userRating === "thumbs-up" || userRating === "block") {
                setShowProgressModal(true);
                dispatch(QAndAThunks.postUserFeedback({
                    question_and_answer: qAndAState.questionAnswer.id,
                    feedback_code: feedbackCodeAction.id
                }));
            }

        }
    }, [userRating]);
    
    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setAskApiInProgress(true);
        dispatch(QAndAThunks.askQuestion(questionText));
    }

    const renderApiStatusMessage = () => {
        if (askApiInProgress === true) {
            return (
                <>Retrieving the answer...</>
            )
        }
        else {
            if (askApiStatus === "failed") {
                <>
                    <SentimentDissatisfiedRounded />
                    <div className="text">
                        Failed to fetch the answer. Try again. 
                    </div>
                </>
            }
        }
    }

    const navigateToFeedbackPage = () => {
        navigate("/feedback");
    }

    const startOver = () => {
        setQuestionText(null);
        setAnswerText(null);
        setUserRating(null);
        setAskApiInProgress(false);
        setAskApiStatus(null);
        dispatch(QAndAThunks.resetStateForNewQuestion());
    }

    return (
        <div className="q-and-a">
            <form className="form" onSubmit={onFormSubmit}>
                <FormControl className="form-field">
                    <TextField
                        className="question-text-field"
                        fullWidth
                        multiline
                        rows={4}
                        inputProps={{maxLength: 1024}}
                        value={questionText !== null ? questionText: ""}
                        placeholder="Please type your question here..."
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                setQuestionText(event.target.value);
                            }
                        } disabled={askApiInProgress || answerText !== null} />
                </FormControl>
                <FormControl className="form-field button-field">
                    <div className="message">
                        {renderApiStatusMessage()}
                    </div>
                    <LoadingButton
                        loading={askApiInProgress}
                        disabled={answerText !== null || questionText === null || (questionText !== null && questionText.trim().length === 0)}
                        type="submit"
                        variant="contained"
                        size="large"
                        className="button">
                        Ask
                    </LoadingButton>
                </FormControl>
            </form>
            {
                (!askApiInProgress && answerText !== null) && (
                    <div className="answer-box">
                        <div className="answer">
                            {Parser(sanitizeHtml(answerText))}
                        </div>
                        <div className="feedback">
                            <div className="ratings">
                                <IconButton
                                    className={userRating === "thumbs-up" ? "selected thumbs-up-button" : "thumbs-up-button"}
                                    title="Answer is satisfactory."
                                    onClick={() => {
                                        if (userRating !== "thumbs-up") {
                                            setUserRating("thumbs-up");
                                        }
                                    }}>
                                    <ThumbUpAltRounded />
                                </IconButton>
                                <IconButton
                                    className={userRating === "thumbs-down" ? "selected thumbs-down-button" : "thumbs-down-button"}
                                    title="Answer is unsatisfactory."
                                    onClick={() => {
                                        if (userRating !== "thumbs-down") {
                                            setUserRating("thumbs-down");
                                        }
                                    }}>
                                    <ThumbDownAltRounded />
                                </IconButton>
                                <IconButton
                                    className="block-button"
                                    title="Block the question"
                                    onClick={() => {
                                        if (userRating !== "block") {
                                            setUserRating("block");
                                        }
                                    }}>
                                    <BlockOutlined/>
                                </IconButton>
                            </div>
                            <div className="action">
                                {
                                    userRating === "thumbs-down" && (
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={navigateToFeedbackPage}
                                        >
                                            Improve Answer
                                        </Button>

                                    )
                                }
                            </div>
                        </div>
                        <div className="powered-by">
                            <div className="text">Powered by</div>
                            <img src={genesisLogoUrl} alt="Genesis Logo" />
                        </div>                        
                    </div>
                )
            }
            <Modal open={showProgressModal} onClose={() => {
                setProgressModalProps({
                    icon: <CircularProgress />,
                    text: "Saving your feedback..."
                })
            }}>
                <Paper className="interactive-progress-modal">
                    <div className="icon">
                        {progressModalProps.icon}
                    </div>
                    <Typography variant="h6">{progressModalProps.text}</Typography>
                </Paper>
            </Modal>
        </div>
    )
}

export default QAndA;