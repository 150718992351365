/* Customised axios instance to facilitate smooth usage and centralised configuration of base url, headers, request/response interceptors. */

import axios, { AxiosInstance } from "axios";

export const abortController = new AbortController();

const Instance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    signal: abortController.signal,
    withCredentials: true
});
function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
Instance.interceptors.request.use(
    (config) => {
        // const authToken = localStorage.getItem("authenticationToken");
        const authToken = localStorage.getItem("authenticationToken");
        let machine_id = localStorage.getItem("machine_id");
        if (!machine_id){
            machine_id = uuidv4()
            localStorage.setItem("machine_id", machine_id)
        }
        config.headers.machine_id = machine_id;
        if (authToken !== null) {
            // config.headers.Authorization = `Bearer ${localStorage.getItem("authenticationToken")}`;
            config.headers.Authorization = `Bearer ${localStorage.getItem("authenticationToken")}`;
        }
        return {
            ...config,
        };
    },
    (error) => {
        return Promise.reject(error);
    },
);

Instance.interceptors.response.use(
    (response) => {
        // If the response status code is in range of 2xx this block is triggered. Use it to process on desired status code.
        return { ...response };
    },
    async (error) => {
        const { config, response } = error;
        if (error.response.status === 429) {
            alert('Apologies!. Our system is currently experiencing a high volume of requests. Please try again later.')
        } else if (config.url === "/api/auth/token/" && error.response.status == 401) {
            alert("Oops! It seems your password is incorrect. If you're having trouble logging in, you can utilize the Forgot Password feature.")
        }
        else if (config.url === "/api/auth/token/refresh/" && (response.data.code === "token_not_valid" || error.response.status == 401)) {
            const showAlert = localStorage.getItem("freeUsageAllowed") == '1' ? true : false
            if (showAlert) {
                alert('Unlock the advantages of preserving your chat history by logging in or registering. Revisit past conversations, initiate new ones, and share your chat experiences with others.')
            }
            localStorage.setItem("freeUsageAllowed", '0');
            localStorage.removeItem("authenticationToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/login";
        }
        else {
             if ((error.response.status === 401 || error.response.status === 403) && !config.retry) {
                config.retry = true;
                try {
                    // const refreshToken = localStorage.getItem("refreshToken");
                    const refreshToken = localStorage.getItem("refreshToken") || 'dummy';
                    const resp = await Instance.post("/api/auth/token/refresh/", {
                        "refresh": refreshToken
                    });
    
                    const { access, refresh } = resp.data;
                    // localStorage.setItem("authenticationToken", access);
                    localStorage.setItem("authenticationToken", access);
                    localStorage.setItem("refreshToken", refresh);
                    Instance.defaults.headers.common["Authorization"] = `Bearer ${access}`;
    
                    return Instance(config);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            } else if (error.response.status === 403){
                // alert('Unlock the advantages of preserving your chat history by logging in or registering. Revisit past conversations, initiate new ones, and share your chat experiences with others.')
                localStorage.removeItem('authenticationToken');
                localStorage.removeItem('refreshToken');
                localStorage.setItem("freeUsageAllowed", '0');
                window.location.href = "/login";
            }
            else if (error.response.status === 401){
                localStorage.removeItem('authenticationToken');
                localStorage.removeItem('refreshToken');
                localStorage.setItem("freeUsageAllowed", '0');
                window.location.href = "/login";
            }
        }

        return Promise.reject({ ...error });
    },
);

export default Instance;
