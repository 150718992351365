import React, { useState } from "react";
import { Dialog } from "@mui/material";
import {
    CapabilitiesIcon,
    ClockIcon,
    HumanIcon,
    InfoIcon,
    LimitationsIcon,
    SmileyIcon,
    TriangleIcon,
    VolumeIcon,
} from "../../../../assets/svgicons";

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const InforDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
    const [switchContent, setSwitchContent] = useState("capabilities");
    const cardContent = (switchContent: any) => {
        return switchContent === "capabilities"
            ? [
                  {
                      text: "Answers in the voice of Marshall Goldsmith",
                      icon: <VolumeIcon />,
                      id: 1,
                  },
                  {
                      text: "Is confined to Marshall’s area of expertise and is well behaved",
                      icon: <SmileyIcon />,
                      id: 2,
                  },
                  {
                      text: "Remembers what the user said earlier in the conversation",
                      icon: <ClockIcon />,
                      id: 3,
                  },
              ]
            : [
                  {
                      text: "Limited to Marshall’s knowledge of Leadership & Coaching",
                      icon: <HumanIcon />,
                      id: 1,
                  },
                  {
                      text: "May occasionally generate factually incorrect information",
                      icon: <InfoIcon />,
                      id: 2,
                  },
                  {
                      text: "May occasionally produce biased content",
                      icon: <TriangleIcon />,
                      id: 3,
                  },
              ];
    };

    return (
        <Dialog open={isOpen} onClose={() => onClose()}>
            <div className="info-pop">
                <div className="inner-container">
                    <div className="header">
                        <div
                            className={`common-container ${
                                switchContent === "capabilities" ? "active" : ""
                            }`}
                            onClick={() => setSwitchContent("capabilities")}
                        >
                            <CapabilitiesIcon
                                color={`${
                                    switchContent === "capabilities"
                                        ? "white"
                                        : "black"
                                }`}
                            />
                            <p>Capabilities</p>
                        </div>
                        <div
                            className={`common-container ${
                                switchContent === "capabilities"
                                    ? ""
                                    : "active2"
                            }`}
                            onClick={() => setSwitchContent("limitations")}
                        >
                            <LimitationsIcon
                                color={`${
                                    switchContent === "limitations"
                                        ? "white"
                                        : "black"
                                }`}
                            />
                            <p>Limitations</p>
                        </div>
                    </div>
                    {cardContent(switchContent).map((each) => (
                        <div className="card" key={each.id}>
                            <div className="icon">{each.icon}</div>
                            <div className="text">{each.text}</div>
                        </div>
                    ))}
                    <div className="button" onClick={() => onClose()}>
                        OK
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default InforDialog;
