import React, { useState, useEffect } from "react";
import "./login.index.less";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
    Close,
    HorizontalRule,
    Lock,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { Dialog, Snackbar } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {
    FormControl,
    OutlinedInput,
    InputAdornment,
    Collapse,
    Alert,
    Paper,
    Typography,
    Button,
    IconButton,
} from "@mui/material";
import mgHeroImageUrl from "../../assets/images/mg-background.png";
import mgFaceCropHeroImageUrl from "../../assets/images/mg-face-crop.jpeg";
import mgLogoImageUrl from "../../assets/images/mg-logo.png";
import fractalLogo from "../../assets/images/fractal-logo.png";
import { RootState } from "../../globals/redux/store/store.index";
import * as LoginThunks from "./login.thunks";
import {
    GoogleLogin,
    GoogleOAuthProvider,
    useGoogleLogin,
} from "@react-oauth/google";
import marshal_bg from "../../assets/images/marshall_bg.png";
import Footer from "../Footer/Footer.index";
const Login = () => {
    // Selectors
    const loginState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.loginReducer,
    );
    const chatState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.chatV2Reducer,
    );
    const [username, setUsername] = useState<string | null>(null);
    const [password, setUserPassword] = useState<string | null>(null);
    const [apiInProgress, setApiInProgress] = useState<boolean>(false);
    const [authenticationStatus, setAuthenticationStatus] = useState<
        string | null
    >(null);
    const [authenticationResponse, setAuthenticationResponse] = useState<
        string | null
    >(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [overloadOpen, setOverloadOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (rememberMe) {
            localStorage.setItem("rememberedUsername", username);
            localStorage.setItem("rememberMe", "true");
        } else {
            // If "Remember Me" is unchecked, remove stored data
            localStorage.removeItem("rememberedUsername");
            localStorage.removeItem("rememberMe");
        }
        event.preventDefault();
        setApiInProgress(true);
        dispatch(LoginThunks.authenticateUser(username, password));
    };

    const handleCreateAccountClick = () => {
        navigate("/register/");
    };

    const handleForgotPasswordClick = () => {
        if ("gtag" in window) {
            (window as any).gtag(
                "event",
                "click",
                {
                    event_category: "custom",
                    event_label: "forgot_password_button_clicked",
                    value: "forgot_password_button_clicked",
                },
                { debug_mode: true },
            );
        }
        navigate("/verify-email/");
    };

    const onClickGoogle = () => {
        setShowOverlay(true);
    };

    const handleGLoginSuccess = (response: any) => {
        //have to send this response or required details to backend api
        localStorage.setItem('freeUsageAllowed', '0')
        dispatch(LoginThunks.googleLogin(response.credential));
        // setShowOverlay(false);
    };

    useEffect(() => {
        // const authToken = localStorage.getItem("authenticationToken");
        const authToken = localStorage.getItem("authenticationToken");
        const authTokenIsValid =
            authToken !== null || (authToken && authToken.trim().length > 0);

        if (authTokenIsValid === true) {
            navigate("/chat");
        } else {
            localStorage.removeItem("question");
            localStorage.removeItem("answer");
        }
        document.title = "Login";
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setAuthenticationStatus(loginState.authenticationStatus);
        if (loginState.authenticationStatus === "successful") {
            setAuthenticationResponse("Login successful!");
            setTimeout(() => {
                setApiInProgress(false);
                navigate("/chat");
            }, 750);
        } else if (loginState.authenticationStatus === "failed") {
            setApiInProgress(false);
            setAuthenticationResponse("Login failed!");
        }
        // eslint-disable-next-line
    }, [loginState]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("rememberedUsername");
        const storedRememberMe = localStorage.getItem("rememberMe");

        if (storedRememberMe) {
            setUsername(storedUsername || "");
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        if (
            chatState.systemOverload &&
            chatState.systemOverload.system_overload
        ) {
            setOverloadOpen(chatState.systemOverload.system_overload);
        }
    }, [chatState.systemOverload]);

    const handleContactClick = () => {
        window.open("https://fractal.ai/contact-us/", "_blank");
    };
    const handleCookieClick = () => {
        window.open("/cookie", "_blank");
    };
    const handleTermsClick = () => {
        window.open("/t&c", "_blank");
    };
    const handlePrivacyClick = () => {
        window.open("/privacy", "_blank");
    };


    return (
        <>
            <div className="login">
                <div className="mg-left-container">
                    <img src={marshal_bg} className="mg-left-image" />
                </div>
                <div className="login-right-container">
                    <div className="inside-container">
                        <div className="card-first-part">
                            <a href="https://marshallgoldsmith.ai/" rel="noopener noreferrer">
                                <img
                                    src={fractalLogo}
                                    alt="Logo"
                                    className="logo-image"
                                />
                            </a>
                            <div className="vertical-line"></div>
                            <a href="https://marshallgoldsmith.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={mgLogoImageUrl}
                                    alt="MG-Logo"
                                    className="logo-image"
                                />
                            </a>
                        </div>
                        <div className="card-second-part">
                            <div className="google-login">
                                <GoogleOAuthProvider
                                    clientId={
                                        process.env.REACT_APP_GOOGLE_CLIENT_ID
                                    }
                                >
                                    <GoogleLogin
                                        size="large"
                                        type="standard"
                                        width={"360px"}
                                        theme="filled_blue"
                                        intermediate_iframe_close_callback={() =>
                                            console.log("closed")
                                        }
                                        click_listener={() => onClickGoogle()}
                                        onSuccess={(credentialResponse) => {
                                            handleGLoginSuccess(
                                                credentialResponse,
                                            );
                                        }}
                                        onError={() => {
                                            setShowOverlay(false);
                                            console.log("error in login");
                                        }}
                                    />
                                </GoogleOAuthProvider>
                            </div>
                            <div className="sign-in-line">
                                <div>
                                    <hr className="horizontal-line" />
                                </div>
                                <div className="sign-in-text">
                                    or, sign in with your email
                                </div>
                                <div>
                                    <hr className="horizontal-line" />
                                </div>
                            </div>
                            <form onSubmit={onFormSubmit} className="form">
                                <FormControl className="form-field">
                                    <label
                                        htmlFor="email-input"
                                        className="label-text"
                                    >
                                        Email Address
                                    </label>
                                    <OutlinedInput
                                        notched
                                        sx={{
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: "#236B48",
                                            },
                                        }}
                                        id="email-input"
                                        fullWidth
                                        type="text"
                                        autoFocus
                                        placeholder="Email"
                                        className="text-input"
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            setUsername(
                                                (username) =>
                                                    event.target.value,
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl className="form-field">
                                    <label
                                        htmlFor="password-input"
                                        className="label-text"
                                    >
                                        Password
                                    </label>
                                    <OutlinedInput
                                        sx={{
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: "#236B48",
                                            },
                                        }}
                                        id="password-input"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        placeholder="Password"
                                        className="text-input"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword,
                                                        )
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            setUserPassword(
                                                (userPassword) =>
                                                    event.target.value,
                                            );
                                        }}
                                    />
                                </FormControl>
                                <div className="checkbox-container">
                                    <input
                                        id="checkbox"
                                        type="checkbox"
                                        className="checkbox"
                                        style={{ backgroundColor: "green" }}
                                        checked={rememberMe}
                                        onChange={() =>
                                            setRememberMe(!rememberMe)
                                        }
                                    />
                                    <label htmlFor="checkbox">
                                        Remember Me
                                    </label>
                                </div>
                                <FormControl className="form-field button-field">
                                    <LoadingButton
                                        sx={{
                                            backgroundColor: "#236B48",
                                            "&:hover": {
                                                backgroundColor: "#236B48",
                                            },
                                        }}
                                        loading={apiInProgress}
                                        variant="contained"
                                        type="submit"
                                        size="large"
                                        className="button"
                                        style={{ textTransform: "none" }}
                                    >
                                        Sign In
                                    </LoadingButton>
                                </FormControl>
                            </form>
                            <Collapse
                                className="collapse-bar"
                                orientation="vertical"
                                in={authenticationStatus !== null}
                            >
                                <Alert
                                    sx={{ width: "360px" }}
                                    severity={
                                        authenticationStatus === "successful"
                                            ? "success"
                                            : "error"
                                    }
                                >
                                    {authenticationResponse}
                                </Alert>
                            </Collapse>
                            <div className="bottom-container">
                                <div
                                    className="customButton"
                                    onClick={handleCreateAccountClick}
                                >
                                    Create Account
                                </div>
                                <div
                                    id="forgot-password-button"
                                    className="customButton"
                                    onClick={handleForgotPasswordClick}
                                >
                                    Forgot Password?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            {showOverlay && authenticationResponse && (
                <div className="overlay-for-google-signin">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        className="loading-icon"
                        viewBox="0 0 22 22"
                        fill="none"
                    >
                        <path
                            fill="white"
                            d="M9 18A9 9 0 1 1 9 0v2a7 7 0 1 0 7 7h2a9.01 9.01 0 0 1-9 9z"
                            transform="translate(2.001 2)"
                        />
                    </svg>
                    <p>{authenticationResponse}</p>
                </div>
            )}
            <Snackbar
                sx={{ maxWidth: 340 }}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={overloadOpen}
                autoHideDuration={3000}
                message={chatState.systemOverload.message}
                onClose={() => setOverloadOpen(false)}
                action={
                    <IconButton onClick={() => setOverloadOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                }
            />
        </>
    );
};

export default Login;
