import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import "./conversationSharing.index.less";
import { ConversationSharingService } from '../../../services/conversationSharingService';
import userImageUrl from "../../../assets/images/user.png";
import botImageUrl from "../../../assets/images/mg-face-crop.jpeg";
import Parser from "html-react-parser";
import sanitizeHtml from "sanitize-html";

const ConversationSharing = () => {
    let { sessionId, shareId } = useParams();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        fetchMessages(sessionId, shareId);
    }, [sessionId, shareId]);

    const fetchMessages = async (sessionId, shareId) => {
        try {
            const response = await ConversationSharingService.fetchMessages(sessionId, shareId);
            setMessages(response.data)
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching shareId:', error);
        }
    };

    return (
        <>
            <div className="main-container">
                <div className="msg-container">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={message.sender === 'user' ? 'sent message' : 'received message'}
                        >
                            <div className={message.sender === "user" ? "top-user" : "top"}>
                                {message.sender === "user" && (
                                    <>
                                        <div className="user-text">
                                            {Parser(
                                                sanitizeHtml(
                                                    message.message
                                                ),
                                            )}
                                        </div>
                                        <div className="image">
                                            <img
                                                src={userImageUrl}
                                                alt="ub"
                                                className="image-user"
                                            />
                                        </div>
                                    </>
                                )}
                                {message.sender === "assistant" && (
                                    <>
                                        <div className="image">
                                            <img src={botImageUrl} alt="ub" className="image" />
                                        </div>
                                        <div className="answer-block">
                                            <div className="text-t">
                                                {message.sender === "assistant" ? (
                                                    <ReactMarkdown>
                                                        {message.message}
                                                    </ReactMarkdown>
                                                ) : (
                                                    Parser(
                                                        sanitizeHtml(
                                                            message.message
                                                        ),
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ConversationSharing;
