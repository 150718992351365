import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, FormGroup, Checkbox, Button, TextField } from "@mui/material";

interface DialogProps {
    isOpen: boolean;
    messageFeedbackApiStatus: string;
    onClose: () => void;
    onConfirm: (feedbackText: string | null, selectedFeedbackOptions: string[]) => void;
}

const FeedbackDialog: React.FC<DialogProps> = ({
    isOpen,
    messageFeedbackApiStatus,
    onClose,
    onConfirm
}) => {

    const feedbackOptions = [
        "This is harmful/unsafe",
        "This isn't true",
        "This isn't helpful",
    ]

    const [feedbackText, setFeedbackText] = useState<string | null>(null);
    const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<string[]>([]);

    useEffect(() => {
        if (messageFeedbackApiStatus === "success") {
            setSelectedFeedbackOptions([]);
        }
    }, [messageFeedbackApiStatus]);

    const onDialogClose = () => {
        setFeedbackText(null);
        setSelectedFeedbackOptions([]);
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={(event, reason) => {
            if (reason === "escapeKeyDown" || reason === "backdropClick") {
                return
            }
            onDialogClose();
        }}>
            <DialogTitle component="h6">
                Provide Additional Feedback
            </DialogTitle>
            <DialogContent sx={{ width: 600 }}>
                <FormGroup>
                    {
                        feedbackOptions.map((option: string, index: number) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={selectedFeedbackOptions.indexOf(option) > -1}
                                            onChange={(event) => {
                                                let currentSelection = [...selectedFeedbackOptions];

                                                if (event.target.checked) {

                                                    if (currentSelection.indexOf(option) < 0) {
                                                        currentSelection.push(option);
                                                    }

                                                }
                                                else {

                                                    if (currentSelection.indexOf(option) > -1) {
                                                        currentSelection = currentSelection.filter((item: string) => item !== option);
                                                    }
                                                }

                                                setSelectedFeedbackOptions(currentSelection);
                                            }}
                                            size="small"
                                        />
                                    }
                                    label={option}
                                />
                            )
                        })
                    }
                </FormGroup>
                <TextField
                    fullWidth
                    multiline
                    rows={12}
                    error={feedbackText !== null && feedbackText.trim() === ""}
                    helperText="Feedback cannot be blank string"
                    onChange={(event) => setFeedbackText(event.target.value)}
                    placeholder="Type your feedback here"
                    onBlur={() => {
                        if (feedbackText !== null && feedbackText.trim() === "") {
                            setFeedbackText(null);
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    variant="text"
                    color="secondary"
                    disabled={messageFeedbackApiStatus === "inprogress"}
                    onClick={onDialogClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    loading={messageFeedbackApiStatus === "inprogress"}
                    disabled={feedbackText && feedbackText.trim().length === 0 && selectedFeedbackOptions.length === 0}
                    variant="contained"
                    onClick={(event) => {
                        onConfirm(feedbackText, selectedFeedbackOptions);
                    }}
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default FeedbackDialog;