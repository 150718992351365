import Axios from "../globals/axios/axios.index";

export const AuthenticationService = {
    authentication: (username: string, password: string) => {
        const data: Record<any, any> = {
            username: username,
            password: password,
        };

        return Axios.post("/api/auth/token/", data);
    },
    googlelogin: (token: string) => {
        const data: Record<any, any> = {
            user_token: token,
        };

        return Axios.post("/api/v1/auth/gmail/", data);
    },
};
