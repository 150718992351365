import { combineReducers } from "redux";
import { ChatReducer as ChatV2Reducer } from "../../../pages/chatV2/chat.reducer";
import { FeedbackReducer } from "../../../pages/feedback/feedback.reducers";
import LayoutReducer from "../../../pages/layout/layout.reducer";
import { LoginReducer } from "../../../pages/login/login.reducer";
import { QAndAReducer } from "../../../pages/qAndA/qAndA.reducer";
import { RegisterReducer } from "../../../pages/register/register.reducer";

const reducer = combineReducers({
    globalStateReducer: LayoutReducer,
    loginReducer: LoginReducer,
    qAndAReducer: QAndAReducer,
    feedbackReducer: FeedbackReducer,
    registerReducer: RegisterReducer,
    chatV2Reducer: ChatV2Reducer,
});

const rootReducer = (state: any, action: any) => {

    if (action.type === "RESET_STATE") {
        state = undefined;
    }
    return reducer(state, action);

};

export default rootReducer;
