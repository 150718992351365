import { Cancel } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom"
import "./pageNotFound.index.less";


const PageNotFound: React.FC<{}> = () => {

    const navigate = useNavigate(); 

    return (
        <div className="page-not-found-container">
            <div className="message-box">
                <Cancel htmlColor="#ec3225" sx={{ width: "4em", height: "4em", marginBottom: 2 }} />
                <Typography variant="h4" align="center" sx={{ fontWeight: "bold", marginBottom: 2 }}>Dead End!</Typography>
                <Typography variant="h6" align="center" sx={{ marginBottom: 3 }}>
                    You have reached a dead end!
                </Typography>
                <Button variant="contained" className="button" onClick={() => {
                    navigate("/chat");
                }}>
                    Go To Home Page
                </Button>
            </div>
        </div>
    )
}


export default PageNotFound;