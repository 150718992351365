import Axios from "../globals/axios/axios.index";

export const ConversationSharingService = {

    fetchShareId: (chatId: string) => {
        const authToken = localStorage.getItem("authenticationToken");
        return Axios.post(`/api/v1/chat/generate_share_id/${chatId}/`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },

    fetchMessages: (chatId: string, shareId: string) => {
        return Axios.get(`/api/v1/chat/get_shared_chat_messages/${chatId}/${shareId}/`);
    }
}