export const SET_QUESTION_ANSWER = "Q_AND_A/SET_QUESTION_ANSWER";
export const SET_ASK_API_CALL_STATUS = "Q_AND_A/SET_ASK_API_CALL_STATUS";
export const SET_FEEDBACK_CODES = "Q_AND_A/SET_FEEDBACK_CODES";
export const SET_FEEDBACK_CODE_API_CALL_STATUS = "Q_AND_A/SET_FEEDBACK_CODE_API_CALL_STATUS";
export const SET_QUESTION_ID = "Q_AND_A/SET_QUESTION_ID";
export const SET_SELECTED_USER_FEEDBACK_ACTION = "Q_AND_A/SET_SELECTED_USER_FEEDBACK_ACTION";
export const SET_USER_FEEDBACK_API_CALL_STATUS = "Q_AND_A/SET_USER_FEEDBACK_API_CALL_STATUS";
export const RESET_STATE_FOR_NEW_QUESTION = "Q_AND_A/RESET_STATE_FOR_NEW_QUESTION";

export const setQuestionAnswer = (data: Record<any, any>) => ({
    type: SET_QUESTION_ANSWER,
    payload: {
        questionAnswer: data
    }
});

export const setAskApiCallStatus = (status: string) => ({
    type: SET_ASK_API_CALL_STATUS,
    payload: {
        askApiCallStatus: status
    }
});

export const setFeedbackCodes = (codes: Record<any, any>[]) => ({
    type: SET_FEEDBACK_CODES,
    payload: {
        feedbackCodes: codes
    }
});

export const setFeedbackCodeApiCallStatus = (status: string) => ({
    type: SET_FEEDBACK_CODE_API_CALL_STATUS,
    payload: {
        feedbackCodeApiCallStatus: status
    }
});

export const setQuestionId = (questionId: number | null) => ({
    type: SET_QUESTION_ID,
    payload: {
        questionId: questionId
    }
});

export const setSelectedUserFeedbackAction = (action: Record<any, any> | null) => ({
    type: SET_SELECTED_USER_FEEDBACK_ACTION,
    payload: {
        selectedUserFeedbackAction: action
    }
});

export const setUserFeedbackApiCallStatus = (status: string) => ({
    type: SET_USER_FEEDBACK_API_CALL_STATUS,
    payload: {
        userFeedbackApiCallStatus: status
    }
});

export const resetStateForNewQuestion = () => ({
    type: RESET_STATE_FOR_NEW_QUESTION,
    payload: {
        question: null,
        answer: null,
        askApiCallStatus: null,
        questionId: null,
        selectedUserFeedbackAction: null,
        userFeedbackApiCallStatus: null,
    }
});