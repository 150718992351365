import React from "react";
import LandingPageDesktop from "./landingPageDesktop.index";
import LandingPageMobile from "./landingPageMobile.index";
import LandingPageNew from "./landingPageNew.index";

const LandingPage = () => {
    // Function to check if the screen width is below a certain breakpoint (e.g., 768px)
    const isMobile = () => window.innerWidth <= 992;

    // return (
    //   <div>
    //     {isMobile() ? (
    //       <LandingPageMobile />
    //     ) : (
    //       <LandingPageDesktop />
    //     )}
    //   </div>
    // );
    return (
        <div>
            <LandingPageNew />
        </div>
    );
};

export default LandingPage;
