import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface ComponentProps {
    path: string,
    children: ReactElement
}


const ProtectedRoute:React.FC<ComponentProps> = ({path, children}) => {
    
    // const authToken = localStorage.getItem("authenticationToken");
    const authToken = localStorage.getItem("authenticationToken");
    const authTokenIsValid = authToken !== null && authToken.trim().length > 0;
    const freeUsage = localStorage.getItem('freeUsageAllowed')
    if (!authTokenIsValid && (freeUsage == '0' || !freeUsage)) {
        return <Navigate to="/login"/>
    }

    return children;
}

export default ProtectedRoute;