import { Dispatch } from "redux";
import appStateModel from "../../globals/redux/models";
import * as FeedbackActions from "./feedback.actions";
import * as GlobalThunks from "../layout/layout.thunks";
import { QAndAService } from "../../services/qAndAService";


export const postUserFeedback = (data: Record<any, any>) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            const response = await QAndAService.postUserFeedback(data);
            dispatch(FeedbackActions.setFeedbackApiCallStatus("successful"));
        } catch (error) {
            dispatch(FeedbackActions.setFeedbackApiCallStatus("failed"));
        }
    }
}


export const resetState = () => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(FeedbackActions.resetState());
    }
}