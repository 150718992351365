import Axios from "../globals/axios/axios.index";

export const ResendOtpService = {
    resend: (email: string, flow: string) => {
        const data: Record<string, any> = {
            email: email,
            flow: flow
        };

        return Axios.post("/api/v1/auth/resend-otp/", data);
    },
};
