import React from "react";
import { Button, Typography } from "@mui/material";
import { BlockRounded } from "@mui/icons-material";
import "./errorboundary.index.less";

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError === true) {
            return (
                <div className="error-page">
                    <div className="header"></div>
                    <div className="container">
                        <BlockRounded htmlColor="#ec3225" sx={{ width: "4em", height: "4em", marginBottom: 2 }} />
                        <Typography variant="h4" align="center" sx={{ fontWeight: "bold", marginBottom: 2 }}>Error!</Typography>
                        <Typography variant="h6" align="center" sx={{ marginBottom: 3 }}>
                            We have encountered an error!
                        </Typography>
                        <div className="buttons">
                            <Button variant="contained" className="button" onClick={() => {
                                window.history.back()
                            }}>
                                Go To Home Page
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
