import { Dispatch } from "redux";
import appStateModel from "../../globals/redux/models";
import * as QAndAActions from "./qAndA.actions";
import * as GlobalThunks from "../layout/layout.thunks";
import { QAndAService } from "../../services/qAndAService";


export const fetchFeedbackCodes = () => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            const response = await QAndAService.fetchFeedbackCodes();
            dispatch(QAndAActions.setFeedbackCodes(response.data));
            dispatch(QAndAActions.setFeedbackCodeApiCallStatus("successful"));
        } catch (error) {
            dispatch(QAndAActions.setFeedbackCodeApiCallStatus("failed"));
        }
    }
}

export const askQuestion = (question: string) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            const response = await QAndAService.askQuestion(question);
            
            localStorage.setItem("questionAnswer", JSON.stringify(response.data));
            
            dispatch(QAndAActions.setQuestionAnswer(response.data));
            dispatch(QAndAActions.setAskApiCallStatus("successful"));
        } catch (error) {
            dispatch(QAndAActions.setAskApiCallStatus("failed"));
            dispatch(GlobalThunks.setNotificationMessage(true, error.response.data , "error"));
        }
    };
};

export const setSelectedUserFeedbackAction = (action: Record<any, any> | null) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(QAndAActions.setSelectedUserFeedbackAction(action));
    }
}

export const postUserFeedback = (data: Record<any, any>) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            console.log(data);
            const response = await QAndAService.postUserFeedback(data);
            dispatch(QAndAActions.setUserFeedbackApiCallStatus("successful"));
        } catch (error) {
            dispatch(QAndAActions.setUserFeedbackApiCallStatus("failed"));
        }
    }
}

export const resetStateForNewQuestion = () => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(QAndAActions.resetStateForNewQuestion());
    }
}

export const resetAskApiCallStatus = () => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        dispatch(QAndAActions.setAskApiCallStatus(null));
    }
}