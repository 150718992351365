export const RESET_STATE = "CHATV2/RESET_STATE";
export const SET_CHAT_SESSION_ID = "CHATV2/SET_CHAT_SESSION_ID";
export const SET_SEND_MESSAGE_API_STATUS = "CHATV2/SET_SEND_MESSAGE_API_STATUS";
export const SET_BOT_ANSWERING = "CHATV2/SET_BOT_ANSWERING";
export const SET_CHAT_TYPE_FOR_UPDATE_MSG =
    "CHATVS/SET_CHAT_TYPE_FOR_UPDATE_MSG";
export const APPEND_MESSAGE = "CHATV2/APPEND_MESSAGE";
export const UPDATE_MESSAGE = "CHATV2/UPDATE_MESSAGE";
export const SET_MESSAGE_FEEDBACK_API_STATUS =
    "CHATV2/SET_MESSAGE_FEEDBACK_API_STATUS";
export const UPDATE_MESSAGE_ID_AUDIO_URLS =
    "CHATV2/UPDATE_MESSAGE_ID_AUDIO_URLS";
export const SET_CHAT_TYPE = "CHATV2/SET_CHAT_TYPE";
export const SET_IS_NEW_CHAT = "CHATV2/SET_IS_NEW_CHAT";
export const SET_RESTART_CONVERSATION = "CHATV2/SET_RESTART_CONVERSATION";
export const UPDATE_MESSAGE_ID_VIDEO_URLS =
    "CHATV2/UPDATE_MESSAGE_ID_VIDEO_URLS";
export const CLEAR_VIDEO_URLS = "CHATV2/CLEAR_VIDEO_URLS";
export const SET_LAST_MESSAGE_DELIVERY_STATUS =
    "CHATV2/SET_LAST_MESSAGE_DELIVERY_STATUS";
export const FETCH_MESSAGES_REQUEST = "CHATV2/FETCH_MESSAGES_REQUEST";
export const FETCH_MESSAGES_SUCCESS = "CHATV2/FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILURE = "CHATV2/FETCH_MESSAGES_FAILURE";
export const SET_AUDIO_MESSAGE_ID = "CHATV2/SET_AUDIO_MESSAGE_ID";
export const SET_SYSTEM_OVERLOAD = "CHATV2/SET_SYSTEM_OVERLOAD";
export const SET_MIC_CLICKED = "CHATV2/SET_MIC_CLICKED";
export const SET_TOGGLE_HISTORY="CHATHISTORY/SET_TOGGLE_HISTORY";
export const SET_STREAM_STARTED = "CHATV2/SET_STREAM_STARTED";
export const resetState = () => ({
    type: RESET_STATE,
    payload: {},
});

export const setChatSessionId = (chatSessionId: string | null) => ({
    type: SET_CHAT_SESSION_ID,
    payload: {
        chatSessionId: chatSessionId,
    },
});

export const setSendMessageApiStatus = (
    status: "inprogress" | "success" | "failed" | null,
) => ({
    type: SET_SEND_MESSAGE_API_STATUS,
    payload: {
        sendMessageApiStatus: status,
    },
});

export const isBotAnswering = (
    status: "inprogress" | "success" | "failed" | null,
) => ({
    type: SET_BOT_ANSWERING,
    payload: {
        isBotAnswering: status,
    },
});

export const setSystemOverload = (
    isSystemOverload: boolean,
    message: string,
) => ({
    type: SET_SYSTEM_OVERLOAD,
    payload: {
        systemOverload: {
            system_overload: isSystemOverload,
            message: message,
        },
    },
});

export const setChatTypeForUpdateMessage = (status: "voice" | "text") => ({
    type: SET_CHAT_TYPE_FOR_UPDATE_MSG,
    payload: {
        chatTypeForUpdateMessage: status,
    },
});

export const appendMessage = (message: Record<any, any>) => ({
    type: APPEND_MESSAGE,
    payload: {
        message: message,
    },
});

export const updateMessage = (message: any) => ({
    type: UPDATE_MESSAGE,
    payload: {
        message: message,
    },
});

export const setMessageFeedbackApiStatus = (
    status: "inprogress" | "success" | "failed" | null,
) => ({
    type: SET_MESSAGE_FEEDBACK_API_STATUS,
    payload: {
        messageFeedbackApiStatus: status,
    },
});

export const updateMessageIdAudioUrls = (
    messageId: string,
    audioUrls: Blob[],
) => ({
    type: UPDATE_MESSAGE_ID_AUDIO_URLS,
    payload: {
        messageId: messageId,
        audioUrls: audioUrls,
    },
});

export const setChatType = (type: "text" | "voice" | "video") => ({
    type: SET_CHAT_TYPE,
    payload: {
        chatType: type,
    },
});

export const setIsNewChat = (type: true | false) => ({
    type: SET_IS_NEW_CHAT,
    payload: {
        isNewChat: type,
    },
});

export const setRestartConversation = (restart: boolean) => ({
    type: SET_RESTART_CONVERSATION,
    payload: {
        restartConversation: restart,
    },
});

export const updateMessageIdVideoUrls = (
    messageId: string,
    videoUrls: string[],
) => ({
    type: UPDATE_MESSAGE_ID_VIDEO_URLS,
    payload: {
        messageId: messageId,
        videoUrls: videoUrls,
    },
});

export const clearVideoUrls = (messageId: string) => {
    return {
        type: CLEAR_VIDEO_URLS,
        payload: messageId,
    };
};

export const setLastMessageDeliveryStatus = (
    status: "delivered" | "failed",
) => ({
    type: SET_LAST_MESSAGE_DELIVERY_STATUS,
    payload: {
        status: status,
    },
});

export const fetchMessagesRequest = () => ({
    type: FETCH_MESSAGES_REQUEST,
});

export const fetchMessagesSuccess = (chatSessionId, messages) => ({
    type: FETCH_MESSAGES_SUCCESS,
    payload: { chatSessionId, messages },
});

export const fetchMessagesFailure = (error) => ({
    type: FETCH_MESSAGES_FAILURE,
    payload: { error },
});

export const setAudioMessageId = (audioMessageId: string | null) => {
    return {
        type: SET_AUDIO_MESSAGE_ID,
        payload: {
            audioMessageId: audioMessageId,
        },
    };
};

export const isMicClicked = (status: 1 | 0 | null) => ({
    type: SET_MIC_CLICKED,
    payload: {
        isMicClicked: status,
    },
});

export const setToggleHistory = (
    status:true| false |null,
    ) => ({
   
        type: SET_TOGGLE_HISTORY,
        payload:{
            setToggleHistory:status,
        },
    });

    export const setStreamStarted = (status: 1 | 0 | null) => ({
        type: SET_STREAM_STARTED,
        payload: {
            setStreamStarted: status,
        },
    });

