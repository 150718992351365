import { Dispatch } from "redux";
import appStateModel from "../../globals/redux/models";
import * as LoginActions from "./login.actions";
import { AuthenticationService } from "../../services/authenticationService";
import dayjs from "dayjs";
import { RootState } from "../../globals/redux/store/store.index";
import * as ChatActions from "../chatV2/chat.actions";
export const authenticateUser = (username: string, password: string) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            const response = await AuthenticationService.authentication(
                username,
                password,
            );
            response.data.system_overload &&
                dispatch(
                    ChatActions.setSystemOverload(
                        response.data.system_overload,
                        response.data.message,
                    ),
                );
            const qnaAccessGroupString = "QnA Access Group";
            const betaTesterGroupString = "Beta Testers";
            const videoAccessGroupString = "Video Access Group";
            const canAccessQnA =
                response.data.groups.indexOf(qnaAccessGroupString) > -1;
            const isBetaTester =
                response.data.groups.indexOf(betaTesterGroupString) > -1;
            // const canAccessVideo = response.data.groups.indexOf(videoAccessGroupString) > -1;

            localStorage.setItem("authenticatedUser", username);
            // localStorage.setItem("authenticationToken", response.data.access);
            // localStorage.setItem("refreshToken", response.data.refresh);
            localStorage.setItem("authenticationToken", response.data.access);
            localStorage.setItem("refreshToken", response.data.refresh);
            localStorage.setItem("freeUsageAllowed", '0');
            localStorage.setItem(
                "refreshTokenExpiresOn",
                dayjs(new Date()).add(4, "day").toISOString(),
            );
            localStorage.setItem("canAccessQnA", canAccessQnA ? "yes" : "no");
            localStorage.setItem("isBetaTester", isBetaTester ? "yes" : "no");
            // localStorage.setItem("canAccessVideo", canAccessVideo ? "yes" : "no");
            localStorage.setItem("wsToken", response.data.ws_token);

            dispatch(LoginActions.setAuthenticationStatus("successful"));
        } catch (error) {
            dispatch(LoginActions.setAuthenticationStatus("failed"));
        }
    };
};

export const googleLogin = (token: string) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            const response = await AuthenticationService.googlelogin(token);
            const qnaAccessGroupString = "QnA Access Group";
            const betaTesterGroupString = "Beta Testers";
            const videoAccessGroupString = "Video Access Group";
            const canAccessQnA =
                response.data.groups.indexOf(qnaAccessGroupString) > -1;
            const isBetaTester =
                response.data.groups.indexOf(betaTesterGroupString) > -1;
            // const canAccessVideo = response.data.groups.indexOf(videoAccessGroupString) > -1;

            localStorage.setItem("authenticatedUser", response.data.username);
            // localStorage.setItem("authenticationToken", response.data.access);
            // localStorage.setItem("refreshToken", response.data.refresh);
            localStorage.setItem("authenticationToken", response.data.access);
            localStorage.setItem("refreshToken", response.data.refresh);
            localStorage.setItem(
                "refreshTokenExpiresOn",
                dayjs(new Date()).add(4, "day").toISOString(),
            );
            localStorage.setItem("canAccessQnA", canAccessQnA ? "yes" : "no");
            localStorage.setItem("isBetaTester", isBetaTester ? "yes" : "no");
            // localStorage.setItem("canAccessVideo", canAccessVideo ? "yes" : "no");
            localStorage.setItem("wsToken", response.data.ws_token);
            dispatch(LoginActions.setAuthenticationStatus("successful"));
        } catch (error) {
            dispatch(LoginActions.setAuthenticationStatus("failed"));
        }
    };
};
