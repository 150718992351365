import Axios from "../globals/axios/axios.index";

export const QAndAService = {

    fetchFeedbackCodes: () => {
        return Axios.get("/api/v1/train/feedback-code/");
    },

    askQuestion : (question: string) => {
        const data:Record<any, any> = {
            question: question
        }
    
        return Axios.post("/api/v1/train/question-answer/", data);
    },

    postUserFeedback: (data: Record<any, any>) => {
        return Axios.post("/api/v1/train/user-feedback/", data);
    }
}
