import React, { useEffect, useRef, useState } from "react";
import mgLogo from "../../assets/images/marshall_logo.png";
import fractalLogo from "../../assets/images/poweredbylogo.png";
import marshallImage from "../../assets/images/marshallimage.png";
import marshallImage2 from "../../assets/images/marshall_image2.png";
import userImage from "../../assets/images/user.png";
import mgfaceCrop from "../../assets/images/mg-face-crop.jpeg";
import MgChairImage from "../../assets/images/mg-chair.png";
import MGChairImageMob from "../../assets/images/mg-chair-mob.png";
import ThumbUpIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDownAltOutlined";
import FileCopyIcon from "@mui/icons-material/ContentCopyOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import PsychologyAltSharpIcon from "@mui/icons-material/PsychologyAltSharp";
import ThumbsUpDownSharpIcon from "@mui/icons-material/ThumbsUpDownSharp";
import "./landingPageNew.index.less";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import {
    FBIcon,
    GenAiIcon1,
    GenAiIcon2,
    GenAiIcon3,
    InstagramIcon,
    LikeIcon,
    LinkedinIcon,
    MailIcon,
    PoweredByLogo,
    QuoteIcon,
    ScrollDownArrow,
    StarIcon,
    TickMark,
    TwitterIcon,
    WebIcon,
} from "../../assets/svgicons";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useNavigate } from "react-router-dom";
import ChatV2 from "../chatV2/chat.index";
import { Link } from "react-router-dom";
import three_questions_arrow from "../../assets/images/three-questions_arrow.png";
import Marquee from "../layout/marquee/marquee.index";
import ContactUs from "./ContactUs/ContactUs.index";
import Footer from "../Footer/Footer.index";

const LandingPageNew = () => {
    const isMobile = () => window.innerWidth <= 400;
    console.log("isMobile", isMobile());
    let freeUsage = localStorage.getItem("freeUsageAllowed");
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const landingPageTextPartRef = useRef<HTMLDivElement>(null);
    const [activeButton, setActiveButton] = useState<string>("Why"); // Set 'Why' as the initial active button
    const [activeThumbnails, setActiveThumbnails] = useState<string[]>([
        "J_9E739Oumk",
        "trJitVctns0",
        "gOzGQovP3Dg",
        "oDUHGq7aq4c",
        "wbUJIeska7w",
    ]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [activeVideo, setActiveVideo] = useState<string | null>(null);
    const [activeThumbnailIndex, setActiveThumbnailIndex] = useState<
        number | null
    >(null); // Added state to track active thumbnail index
    useEffect(() => {
        // Generate a UUID
        const generateUUID = () => {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                function (c) {
                    var r = (Math.random() * 16) | 0,
                        v = c === "x" ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                },
            );
        };

        // Set the UUID as a cookie
        const setCookie = (name, value, days) => {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        };

        const machineIdCookie = document.cookie
            .split("; ")
            .find((row) => row.startsWith("machine_id="));
        if (!localStorage.getItem("machine_id")) {
            const machine_id = generateUUID();
            localStorage.setItem("machine_id", machine_id);
            // setCookie('machine_id', machine_id, 365); // Set the cookie to expire in 365 days
        }
        if (!freeUsage) {
            freeUsage = "1";
            localStorage.setItem("freeUsageAllowed", "1");
        }
    }, []);

    const scrollToNextSection = () => {
        // Scroll to the landing page text part
        if (landingPageTextPartRef.current) {
            landingPageTextPartRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    };
    const handleButtonClick = () => {
        if (freeUsage == "1") {
            navigate("/chat");
            // alert('to chat')
        } else {
            navigate("/login");
        }
    };
    const handleContactClick = () => {
        window.open("https://fractal.ai/contact-us/", "_blank");
    };
    const handleCookieClick = () => {
        window.open("/cookie", "_blank");
    };
    const handleTermsClick = () => {
        window.open("/t&c", "_blank");
    };

    const handlePrivacyClick = () => {
        window.open("/privacy", "_blank");
    };
    const responsive = {
        0: { items: 1, width: "100%" },
        568: { items: 1, width: "100%" },
        1024: { items: 2, width: "100%" },
    };

    const items = [
        <div className="carousel-item">
            <div className="chat-card1">
                {/* User message */}
                <div className="user-message">
                    <p>
                        Can you explain the concept of Stakeholder Centered
                        Coaching?
                    </p>
                    <img
                        src={userImage}
                        alt="User"
                        className="user-profile-img"
                    />
                </div>
                {/* Bot message */}
                <div className="bot-message">
                    <img
                        src={mgfaceCrop}
                        alt="Bot"
                        className="bot-profile-img"
                    />
                    <div className="message-footer">
                        <div className="bot-message-content">
                            <p>
                                Stakeholder-centered coaching is a coaching
                                methodology that I developed to help leaders
                                improve their effectiveness by actively
                                involving the people around them, known as
                                stakeholders. These stakeholders can include
                                anyone impacted by the leader's behavior, such
                                as team members, peers, managers, and even
                                family members. The process begins with
                                gathering feedback from these stakeholders to
                                identify the leader's strengths and areas for
                                improvement. This feedback is crucial because it
                                provides a comprehensive view of how the
                                leader's behavior is perceived by others. The
                                leader then selects one or two key behaviors to
                                change, based on this feedback, and works on
                                these behaviors with the support of their coach
                                and stakeholders. The unique aspect of
                                stakeholder-centered coaching is the emphasis on
                                involving stakeholders throughout the coaching
                                process. They provide ongoing feedback, which is
                                not only about past performance but also
                                includes feedforward—suggestions for future
                                behavior. This creates a dynamic and supportive
                                environment for the leader to practice and
                                reinforce new behaviors. The process is designed
                                to be measurable and accountable. Progress is
                                tracked through regular mini-surveys where
                                stakeholders rate the leader's improvement in
                                the chosen behaviors. The ultimate goal is to
                                not only change the leader's behavior but also
                                to shift stakeholders' perceptions, recognizing
                                and reinforcing the leader's efforts and
                                improvements. This approach is grounded in the
                                belief that for leaders to change effectively,
                                they need the support and recognition of those
                                around them. It's a practical, focused, and
                                results-oriented method that has been proven to
                                lead to lasting behavioral change and increased
                                leadership effectiveness.
                            </p>
                        </div>
                        {/* <div className="chat-bot-footer">
                            <div className="button-container">
                                <ThumbUpIcon className="thumbs_up" />
                                <ThumbDownIcon className="thumbs_down" />
                                <FileCopyIcon className="copy" />
                            </div>
                        </div> */}
                        <div className="like-count-container">
                            <LikeIcon />
                            <div>1070</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,

        <div className="carousel-item">
            <div className="chat-card2">
                {/* User message */}
                <div className="user-message">
                    <p>
                        What are some key takeaways from your book 'What Got You
                        Here Won't Get You There'?
                    </p>

                    <img
                        src={userImage}
                        alt="User"
                        className="user-profile-img"
                    />
                </div>
                {/* Bot message */}
                <div className="bot-message">
                    <img
                        src={mgfaceCrop}
                        alt="Bot"
                        className="bot-profile-img"
                    />
                    <div className="message-footer">
                        <div className="bot-message-content">
                            <p>
                                Some key takeaways from my book "What Got You
                                Here Won't Get You There" include: <br />
                                <strong>
                                    {" "}
                                    1. Awareness of Limiting Behaviors:
                                </strong>{" "}
                                Recognize that the behaviors that have
                                contributed to your current success may not be
                                the same ones that will propel you to future
                                success.It's essential to identify and
                                understand the habits that may be holding you
                                back.
                                <br />
                                <strong> 2. The Success Delusion:</strong> Be
                                wary of the success delusion, which is the
                                belief that because you are successful,
                                everything you do must be the reason for your
                                success. This can prevent you from seeing your
                                own flaws and areas that need improvement.
                                <br />
                                <strong> 3. The Twenty Habits:</strong> I
                                outline twenty common habits that can be
                                detrimental to a leader's success, such as
                                failing to give proper recognition, playing
                                favorites, and making destructive comments.
                                Recognizing these habits in yourself is the
                                first step to change.
                                <br />
                                <strong>
                                    {" "}
                                    4. Feedback and Feedforward:
                                </strong>{" "}
                                Embrace feedback as a tool for growth, and focus
                                on feedforward, which involves looking to the
                                future and working on suggestions for
                                improvement rather than dwelling on past
                                mistakes.
                                <br />
                                <strong> 5. Apologizing:</strong> Learn the
                                power of a genuine apology. When you've made a
                                mistake or wronged someone, acknowledging it and
                                apologizing can go a long way in mending
                                relationships and building trust.
                                <br />
                                <strong> 6. Listening:</strong> Develop the
                                skill of active listening. Many leaders need to
                                improve their ability to truly listen to others
                                without judgment or thinking about their
                                response while the other person is speaking.
                                <br />
                                <strong> 7. Thanking:</strong> Show appreciation
                                and gratitude. Regularly expressing thanks can
                                strengthen your relationships and increase your
                                team's morale and engagement.
                                <br />
                                <strong>8. Following Up:</strong> Consistent
                                follow-up is crucial. It shows that you are
                                committed to your development and value the
                                input of your stakeholders.
                                <br />
                                <strong>
                                    9. Courage, Humility, and Discipline:
                                </strong>{" "}
                                These three qualities are essential for leaders
                                who want to achieve positive, lasting change. It
                                takes courage to face the truth about oneself,
                                humility to acknowledge the need for
                                improvement, and discipline to make and sustain
                                the changes.
                                <br />
                                <strong>10. Commitment to Change:</strong>{" "}
                                Finally, understand that knowing what to do is
                                not enough. You must be committed to making
                                changes and persistently work on them to see
                                results. Change is hard, but with dedication and
                                support, it is possible.
                                <br />
                                These takeaways are designed to help leaders at
                                all levels identify and overcome the common
                                pitfalls that can prevent them from reaching
                                their full potential and achieving even greater
                                success.
                            </p>
                        </div>
                        {/* <div className="chat-bot-footer">
                            <div className="button-container">
                                <ThumbUpIcon className="thumbs_up" />
                                <ThumbDownIcon className="thumbs_down" />
                                <FileCopyIcon className="copy" />
                            </div>
                        </div> */}
                        <div className="like-count-container">
                            <LikeIcon />
                            <div>790</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,

        <div className="carousel-item">
            <div className="chat-card3">
                {/* User message */}
                <div className="user-message">
                    <p>
                        What is the most important piece of advice you would
                        give to someone looking to improve their leadership
                        skills?
                    </p>

                    <img
                        src={userImage}
                        alt="User"
                        className="user-profile-img"
                    />
                </div>
                {/* Bot message */}
                <div className="bot-message">
                    <img
                        src={mgfaceCrop}
                        alt="Bot"
                        className="bot-profile-img"
                    />
                    <div className="message-footer">
                        <div className="bot-message-content">
                            <p>
                                The most important piece of advice I would give
                                to someone looking to improve their leadership
                                skills is to engage in what I call "Stakeholder
                                Centered Coaching." This involves a few key
                                steps:
                                <br />
                                <strong>
                                    {" "}
                                    1.Identify a specific goal for behavioral
                                    change
                                </strong>
                                : You need to have a clear, specific goal that
                                comes from the heart. If it doesn't, the effort
                                to change will not be authentic, and it's
                                unlikely to be successful.
                                <br />
                                <strong>
                                    {" "}
                                    2.Write down your goal and keep it in front
                                    of you
                                </strong>
                                : The act of writing down your goal and
                                regularly reviewing it significantly increases
                                your chances of achieving it.
                                <br />
                                <strong>
                                    {" "}
                                    3.Involve a positive support group
                                </strong>
                                : Recruit people who are genuinely interested in
                                seeing you improve. They will provide both
                                corrective feedback and positive reinforcement,
                                which are crucial for making lasting changes.
                                Remember, the journey to becoming a better
                                leader is a process, not an event. It requires
                                courage to seek and accept feedback, humility to
                                acknowledge the need for improvement, and
                                discipline to follow through on the hard work of
                                changing behaviors. Keep these principles in
                                mind, and you'll be on your way to becoming a
                                more effective leader.
                            </p>
                        </div>
                        {/* <div className="chat-bot-footer">
                            <div className="button-container">
                                <ThumbUpIcon className="thumbs_up" />
                                <ThumbDownIcon className="thumbs_down" />
                                <FileCopyIcon className="copy" />
                            </div>
                        </div> */}
                        <div className="like-count-container">
                            <LikeIcon />
                            <div>900</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
    ];

    useEffect(() => {
        const handleViewportChange = () => {
            // Reload the page when the viewport changes
            window.location.reload();
        };

        window.addEventListener("resize", handleViewportChange);

        return () => {
            window.removeEventListener("resize", handleViewportChange);
        };
    }, []);

    const handleButtonClickCustom = (buttonName: string) => {
        setActiveButton(buttonName);
        // Set active thumbnails based on the clicked button
        switch (buttonName) {
            case "Why":
                setActiveThumbnails([
                    "J_9E739Oumk",
                    "trJitVctns0",
                    "gOzGQovP3Dg",
                    "oDUHGq7aq4c",
                    "wbUJIeska7w",
                ]);
                break;
            case "What":
                setActiveThumbnails([
                    "LMSb8key0j4",
                    "v7vgtrlJzA0",
                    "JN-wWmb8jHA",
                    "8hl0qCWOZgY",
                    "YifKmvaUKXw",
                ]);
                break;
            case "Who":
                setActiveThumbnails([
                    "8V8fA6egMOI",
                    "AAx6HyPaAOo",
                    "OJ4neNIiHxs",
                ]);
                break;
            case "How":
                setActiveThumbnails([
                    "OJ4neNIiHxs",
                    "mZ3gC_kI_0s",
                    "AcqHDFX2XNA",
                ]);
                break;
            default:
                setActiveThumbnails([]);
        }
        // const Container = document.querySelector('.thumbnails-container');
        // if (Container) {
        //     Container.scrollTo({ top: 0, behavior: 'smooth' });
        // }
    };

    const handleScroll = (event: any) => {
        const position = event.target.scrollTop;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // const playVideo = (videoId: string) => {
    //     setActiveVideo(videoId);
    //     setOpenModal(true);
    // };

    const modalStyle = {
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };
    // const handleCloseModal = () => {
    //     setOpenModal(false);
    //     setActiveVideo(null);
    // };

    // const handleThumbnailClick = (index: number) => {
    //     if (index === activeThumbnails.length - 1) {
    //         // If the clicked thumbnail is the last one, loop back to the initial one
    //         setActiveThumbnailIndex(0);
    //     } else {
    //         // Otherwise, set the active thumbnail index to the clicked index
    //         setActiveThumbnailIndex(index);
    //     }
    //     playVideo(activeThumbnails[index]); // Play video associated with the clicked thumbnail
    // };

    const carouselResponsiveConfig = {
        0: { items: 1, width: "100%" },
        568: { items: 2, width: "100%" },
        1024: { items: 3, width: "100%" },
    };

    const thumbnailItems = activeThumbnails.map((videoId, index) => (
        <div className="thumbnail" key={index}>
            <iframe
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "98%",
                    height: "100%",
                }}
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    ));
    // const concatenatedThumbnailItems = [...thumbnailItems, ...thumbnailItems];

    return (
        <div className="landing-page-main">
            {/* <Marquee   text="Access Marshall's extensive knowledge repository with our APIs. Click here" /> */}
            <div className="landing-page-header">
                <div className="mg-logo">
                    <img src={mgLogo} alt="mg-logo" />
                </div>
                <div className="right-pane">
                    <div
                        className="lets-chat-button"
                        onClick={handleButtonClick}
                    >
                        Ask Marshall
                    </div>
                    <div className="powered-by-logo">
                        {/* <img src={fractalLogo} alt="fractal-logo" height={13} /> */}
                        <a
                            href="https://fractal.ai/"
                            target="_blank"
                            className="each-icon"
                        >
                            <PoweredByLogo width={"87"} height={"19"} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="landing-page-second-part" onScroll={handleScroll}>
                <div className={`landing-page-transition-part `}>
                    <div>
                        <div className="marshall-text">Marshall's wisdom</div>
                        <div className="marshall-text2">Finally, for all</div>
                    </div>
                    <div
                        className="marshall-image"
                        style={
                            scrollPosition <= 900
                                ? {
                                      transform: `scale(${
                                          1 + scrollPosition * 0.004
                                      })`,
                                      opacity: 1 - scrollPosition * 0.0019,
                                  }
                                : {}
                        }
                    >
                        <img src={marshallImage} alt="marshall" />
                    </div>
                    <div
                        className="scroll-down-arrow"
                        onClick={scrollToNextSection}
                        style={{
                            opacity: 1 - scrollPosition * 0.002,
                        }}
                    >
                        <ScrollDownArrow />
                    </div>
                </div>
                <div
                    className="landing-page-text-part"
                    ref={landingPageTextPartRef}
                >
                    <div className="mg-image-container">
                        <img
                            src={
                                window.innerWidth <= 767
                                    ? MGChairImageMob
                                    : MgChairImage
                            }
                            className="mg-chair-image"
                        />
                    </div>
                    <div className="text-and-button">
                        <div className="text-content">
                            <p>I know, it's lonely at the top.</p>
                            <p>
                                Being a leader can feel like sailing a ship all
                                by oneself.
                                <br /> Guiding & coaching top leaders of large
                                organizations has been my life's work.
                                <br />
                                However, I could not be there everywhere and for
                                all.
                            </p>
                            <p>
                                Crafted by Fractal, my Al Avatar is infused with
                                my knowledge, experience, nuances and even my
                                voice to authentically answer management related
                                questions leaders have.
                            </p>
                            <p>
                                Now accessible to all. At scale. Forever.
                                <br />
                                Try it out and share with others.
                                <br />
                            </p>
                        </div>

                        <div
                            className="lets-chat-button"
                            onClick={handleButtonClick}
                        >
                            Ask Marshall
                        </div>
                    </div>
                </div>
                <div className="points">
                    <div className="points-box">
                        <div className="box-contents">
                            <div className="image-container">
                                <ForumIcon className="icons" />
                            </div>
                            <p className="right-text">
                                Pose clear, specific questions to get the most
                                relevant advice.
                            </p>
                        </div>
                    </div>
                    <div className="points-box">
                        <div className="box-contents">
                            <div className="image-container">
                                <PsychologyAltSharpIcon className="icons" />
                            </div>
                            <p className="right-text">
                                Ask questions within Marshall's expertise for
                                insightful responses.
                            </p>
                        </div>
                    </div>
                    <div className="points-box">
                        <div className="box-contents">
                            <div className="image-container">
                                <ThumbsUpDownSharpIcon className="icons" />
                            </div>
                            <p className="right-text">
                                Provide your feedback to help us improve the
                                responses further.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="three-points">
                    <div className="first-point">
                        <div className="tick-mark">
                            <TickMark />
                        </div>
                        <div>Ask your questions</div>
                    </div>
                    <div className="second-point">
                        <div className="tick-mark">
                            <TickMark />
                        </div>
                        <div>
                            Keep your questions pertaining to Marshall’s area of
                            expertise to get accurate and insightful responses
                        </div>
                    </div>
                    <div className="third-point">
                        <div className="tick-mark">
                            <TickMark />
                        </div>
                        <div>
                            Provide us your feedback to help improve the
                            responses further
                        </div>
                    </div>
                </div> */}

                {freeUsage === "1" && (
                    <>
                        <div className="threeQuestionsBox">
                            <div className="free-three-questions-container">
                                <p className="free-three-questions">
                                    Get started with 3 questions without sign in
                                </p>
                                <img
                                    src={three_questions_arrow}
                                    alt="three_questions_arrow"
                                    className="three_questions_arrow"
                                />
                            </div>

                            <div>
                                <ChatV2 />
                            </div>

                            <div className="signInNotes">
                                <div className="signInNotesInnerBox">
                                    <Link to="/Login">
                                        <button>
                                            Sign in for Unlimited Questions
                                        </button>
                                    </Link>
                                </div>
                                <div className="signin-points">
                                    <div className="first-point">
                                        <div className="tick-mark">
                                            <TickMark />
                                        </div>
                                        <div>It's Free - Now and Forever</div>
                                    </div>
                                    <div className="second-point">
                                        <div className="tick-mark">
                                            <TickMark />
                                        </div>
                                        <div>
                                            Maintain your chat histories forever
                                        </div>
                                    </div>
                                    <div className="third-point">
                                        <div className="tick-mark">
                                            <TickMark />
                                        </div>
                                        <div>Easy, safe and secure. Enjoy!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <ContactUs />
                {/* 
                <div className="chat-container">
                    <div className="chat-header">
                        <StarIcon />
                        <div>Most Liked Questions</div>
                    </div>
                    <div className="carousel-container">
                        <div className="carousel">
                            <AliceCarousel
                                mouseTracking
                                items={items}
                                responsive={responsive}
                                autoWidth
                                infinite={true}
                                keyboardNavigation
                                disableButtonsControls
                            />
                        </div>
                    </div>
                </div>
                 */}

                <div className="container">
                    <div className="container-button">
                        <button
                            className={activeButton === "Why" ? "active" : ""}
                            onClick={() => handleButtonClickCustom("Why")}
                        >
                            Why
                            {activeButton === "Why" && (
                                <span className="arrow">&#9660;</span>
                            )}
                        </button>
                        <button
                            className={activeButton === "What" ? "active" : ""}
                            onClick={() => handleButtonClickCustom("What")}
                        >
                            What
                            {activeButton === "What" && (
                                <span className="arrow">&#9660;</span>
                            )}
                        </button>
                        <button
                            className={activeButton === "Who" ? "active" : ""}
                            onClick={() => handleButtonClickCustom("Who")}
                        >
                            Who
                            {activeButton === "Who" && (
                                <span className="arrow">&#9660;</span>
                            )}
                        </button>
                        <button
                            className={activeButton === "How" ? "active" : ""}
                            onClick={() => handleButtonClickCustom("How")}
                        >
                            How
                            {activeButton === "How" && (
                                <span className="arrow">&#9660;</span>
                            )}
                        </button>
                    </div>
                </div>

                <div className="thumbnails-container">
                    <div className="carousel-container-youtube">
                        <div className="carousel-buttons"></div>
                        <div className="carousel">
                            <AliceCarousel
                                mouseTracking
                                items={thumbnailItems}
                                responsive={carouselResponsiveConfig}
                                autoWidth
                                keyboardNavigation
                                disableButtonsControls
                                infinite
                            />
                        </div>
                    </div>
                </div>
                {/* <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${activeVideo}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Box>
                </Modal> */}

                <div className="name-and-text-container">
                    <div className="name-marshall">
                        Marshall <br />
                        Goldsmith
                    </div>
                    <div className="marshall-image-container2">
                        <img src={marshallImage2} alt="marshall" />
                    </div>
                    <div className="marshall-desc">
                        Dr. Marshall Goldsmith is recognized as one of the Top
                        Ten Business Thinkers in the World and the top-rated
                        executive coach. His books have been New York Times and
                        Wall Street Journal #1 Bestsellers and award winners,
                        world over.
                    </div>
                    <div className="social-icons">
                        <a
                            href="https://www.linkedin.com/in/marshallgoldsmith"
                            target="_blank"
                            className="each-icon"
                        >
                            <LinkedinIcon />
                        </a>
                        <a
                            href="https://www.facebook.com/Marshall.Goldsmith.Library/"
                            target="_blank"
                            className="each-icon"
                        >
                            <FBIcon />
                        </a>
                        <a
                            href="https://twitter.com/coachgoldsmith/"
                            target="_blank"
                            className="each-icon"
                        >
                            <TwitterIcon />
                        </a>
                        <a
                            href="https://www.instagram.com/coachgoldsmith/"
                            target="_blank"
                            className="each-icon"
                        >
                            <InstagramIcon />
                        </a>
                        {/* <a
                            href="mailto:email_address"
                            target="_blank"
                            className="each-icon"
                        >
                            <MailIcon />
                        </a> */}
                        <a
                            href="https://marshallgoldsmith.com/"
                            target="_blank"
                            className="each-icon"
                        >
                            <WebIcon />
                        </a>
                    </div>
                </div>
                <div className="gen-ai-solutions-container">
                    <div className="gen-ai-header">GenAI solutions used</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div className="gen-ai-three-points">
                            <div className="each-point">
                                <div className="genai-icon">
                                    <GenAiIcon1 />
                                </div>

                                <div>
                                    Synthesising volumes of Marshall’s work over
                                    decades
                                </div>
                            </div>
                            <div className="each-point">
                                <div className="genai-icon">
                                    <GenAiIcon2 />
                                </div>
                                <div>Rendering Marshall’s voice</div>
                            </div>
                            <div className="each-point">
                                <div className="genai-icon">
                                    <GenAiIcon3 />
                                </div>
                                <div>
                                    Rendering Marshall’s video (releasing soon)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gen-ai-text1">Fractal brings</div>
                    <div className="gen-ai-text2">
                        AI + Engineering + Design
                    </div>
                    <div className="gen-ai-text3">
                        to extract deep domain expertise to shape human
                        decisions, responsibly.
                    </div>
                    <div className="quote">
                        <QuoteIcon />
                    </div>
                    <div className="fractal-description">
                        <div>
                            As Co-Founders of Fractal, we have immensely
                            benefited by Marshall’s guidance to fuel Fractal’s
                            growth. Building Marshall’s AI Avatar is our way of
                            expressing gratitude and fulfilling Marshall's wish
                            to democratize his knowledge, while embodying our
                            commitment to make AI for Good and AI for All.
                            Interact with Marshall’s AI Avatar to benefit from
                            his authentic wisdom.
                        </div>
                    </div>
                    <div className="fractal-founders">
                        <div className="person">
                            <div className="name">Srikanth Velamakanni</div>
                            <div className="role">
                                Co-founder, Group Chief Executive <br />
                                and Vice Chairman
                            </div>
                            <div>
                                <a
                                    href="https://www.linkedin.com/in/srikanthvelamakanni?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                                    target="_blank"
                                >
                                    <LinkedinIcon color="#ffffff" />
                                </a>
                            </div>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="person">
                            <div className="name">Pranay Agrawal</div>
                            <div className="role">
                                Co-founder and <br /> Chief Executive Officer
                            </div>
                            <div>
                                <a
                                    href="https://www.linkedin.com/in/pranayfractal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                                    target="_blank"
                                >
                                    <LinkedinIcon color="#ffffff" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default LandingPageNew;
