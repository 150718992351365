import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography, Modal, Paper, CircularProgress } from "@mui/material";
import "./feedback.index.less";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../globals/redux/store/store.index";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import * as FeedbackThunks from "./feedback.thunks";
import * as QAndAThunks from "../qAndA/qAndA.thunks";
import { Cancel, CheckCircle } from "@mui/icons-material";
import Parser from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { CopyToClipboard } from "react-copy-to-clipboard";
import genesisLogoUrl from "../../assets/images/genesis-logo.png";
import Quill from "quill";
import "quill/dist/quill.snow.css";

export const Feedback = () => {

    // Selectors
    const qAndAState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.qAndAReducer,
    );

    const feedbackState = useSelector<RootState, { [key: string]: any }>(
        (state) => state.feedbackReducer,
    );

    const [source, setSource] = useState<string | null>(null);
    const [newAnswer, setNewAnswer] = useState<string | null>(null);
    const [newAnswerCorrectionMode, setNewAnswerCorrectionMode] = useState<string>("1");
    const [showAnswerDialog, setShowAnswerDialog] = useState<boolean>(false);

    const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
    const [progressModalProps, setProgressModalProps] = useState<Record<any, any>>({
        icon: <CircularProgress />,
        text: "Saving your response..."
    });

    const [copyState, setCopyState] = useState<{ value: string, copied: boolean }>({
        value: "",
        copied: false
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (qAndAState.questionAnswer.hasOwnProperty("question") === false) {
            navigate("/chat");
        }
        document.title = "Feedback";
    }, []);

    // Manage user feedback response
    useEffect(() => {
        if (feedbackState.userFeedbackApiCallStatus === "successful") {
            setProgressModalProps({
                icon: <CheckCircle className="success" />,
                text: "Your feedback has been saved."
            });
            setTimeout(() => {
                setShowProgressModal(false);
                dispatch(FeedbackThunks.resetState());
                dispatch(QAndAThunks.resetStateForNewQuestion());
                localStorage.removeItem("questionAnswer");
                navigate("/q-and-a")
            }, 2500);
        }
        else if (feedbackState.userFeedbackApiCallStatus === "failed") {
            setProgressModalProps({
                icon: <Cancel className="failure" />,
                text: "Your feedback could not be saved. Try again."
            });
            setTimeout(() => {
                setShowProgressModal(false);
            }, 2500);
        }
        else {
            setProgressModalProps({
                icon: <CircularProgress className="progress" />,
                text: "Saving your feedback..."
            });
        }
    }, [feedbackState.userFeedbackApiCallStatus]);

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        const FEEDBACK_CODE_ANSWER_WITH_SAME_SOURCE = "a_neg_oca";
        const FEEDBACK_CODE_ANSWER_WITH_DIFF_SOURCE = "a_neg_nca";

        let feedbackCodeAction;

        if (newAnswerCorrectionMode === "1") {
            feedbackCodeAction = qAndAState.feedbackCodes.filter((item: Record<any, any>) => item.code === FEEDBACK_CODE_ANSWER_WITH_SAME_SOURCE);
        }
        else if (newAnswerCorrectionMode === "2") {
            feedbackCodeAction = qAndAState.feedbackCodes.filter((item: Record<any, any>) => item.code === FEEDBACK_CODE_ANSWER_WITH_DIFF_SOURCE);
        }

        let postData = {
            question_and_answer: qAndAState.questionAnswer.id,
            new_source: source,
            new_answer: removeTags(newAnswer),
            feedback_code: feedbackCodeAction[0].id
        }

        dispatch(FeedbackThunks.postUserFeedback(postData));
        setShowProgressModal(true);

    }

    const quillRef = useRef(null);

    useEffect(() => {
        quillRef.current = new Quill('#editor', {
            theme: 'snow',
            modules: {
                toolbar: false,
            },
            placeholder: "Please type your answer here...",
        });

        quillRef.current.root.style.fontSize = '16px';
        quillRef.current.on('text-change', handleEditorChange);

        return () => {
            quillRef.current.off('text-change', handleEditorChange);
            quillRef.current = null;
        };
    }, []);

    const handleEditorChange = () => {
        const editorContent = quillRef.current.root.innerHTML;
        setNewAnswer(editorContent)
    };

    const renderFeedbackComponent = () => {
        if (newAnswerCorrectionMode === "1") {
            return (
                <form className="form" onSubmit={onFormSubmit}>
                    <FormControl className="form-field">
                        <div className="header">
                            Source
                        </div>
                        <div className="content">
                            {qAndAState.questionAnswer.context ? Parser(sanitizeHtml(qAndAState.questionAnswer.context)) : "No context available"}
                        </div>
                    </FormControl>
                    <FormControl className="form-field">
                        <div className="question">
                            <div className="question-text">{qAndAState.questionAnswer.question}</div>
                            <Button color="secondary" variant="text" className="text-button" onClick={() => {
                                setShowAnswerDialog(true);
                            }}>See Received Answer</Button>
                        </div>
                        
                        <div id="editor" style={{ height: "300px" }}></div>

                        <div className="button-field">
                            <div className="message"></div>
                            <LoadingButton disabled={false} type="submit" variant="contained">
                                Update Answer
                            </LoadingButton>
                        </div>
                    </FormControl>
                    <div className="powered-by">
                        <div className="text">Powered by</div>
                        <img src={genesisLogoUrl} alt="Genesis Logo" />
                    </div>
                </form>
            );
        }
        else if (newAnswerCorrectionMode === "2") {
            return (
                <form className="form" onSubmit={onFormSubmit}>
                    <FormControl className="form-field">
                        <div className="header">
                            Source
                        </div>
                        <TextField
                            className="source-text-field"
                            fullWidth
                            multiline
                            rows={4}
                            value={source !== null ? source : ""}
                            placeholder="Please type or paste your source text or reference link here..."
                            inputProps={{ maxLength: 20000 }}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSource(event.target.value);
                                }
                            } />
                    </FormControl>
                    <FormControl className="form-field">
                        <div className="question">
                            <div className="question-text">{qAndAState.questionAnswer.question}</div>
                            <Button color="secondary" variant="text" className="text-button" onClick={() => {
                                setShowAnswerDialog(true);
                            }}>See Received Answer</Button>
                        </div>
                        
                        <div id="editor" style={{ height: "240px" }}></div>

                        <div className="button-field">
                            <div className="message"></div>
                            <LoadingButton type="submit" variant="contained">
                                Update Answer
                            </LoadingButton>
                        </div>
                    </FormControl>
                    <div className="powered-by">
                        <div className="text">Powered by</div>
                        <img src={genesisLogoUrl} alt="Genesis Logo" />
                    </div>
                </form>
            );
        }
    }

    return (
        <div className="q-and-a-feedback">
            <div className="options">
                <div className="header">I am sorry that the answer was unsatisfactory.<br />Can you please teach me?</div>
                <FormControl>
                    <RadioGroup className="radio-group" value={newAnswerCorrectionMode} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setNewAnswerCorrectionMode(event.target.value);
                    }}>
                        <FormControlLabel className="radio" value="1" control={<Radio size="small" />} label="Provide a new answer" />
                        <FormControlLabel className="radio" value="2" control={<Radio size="small" />} label="Provide a new source and an answer" />
                    </RadioGroup>
                </FormControl>
            </div>
            {renderFeedbackComponent()}
            <Dialog open={showAnswerDialog} onClose={() => {
                setShowAnswerDialog(false);
            }}>
                <DialogTitle>Received Answer</DialogTitle>
                <DialogContent>
                    {Parser(sanitizeHtml(qAndAState.questionAnswer.answer))}
                </DialogContent>
                <DialogActions>
                    <CopyToClipboard text={qAndAState.questionAnswer.answer} onCopy={() => {
                        setCopyState({ copied: true, value: qAndAState.questionAnswer.answer })
                    }} >
                        <Button variant="text" color="secondary">Copy Answer</Button>
                    </CopyToClipboard>
                    <Button variant="text" onClick={() => {
                        setShowAnswerDialog(false);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Modal open={showProgressModal} onClose={() => {
                setProgressModalProps({
                    icon: <CircularProgress />,
                    text: "Saving your feedback..."
                });
            }}>
                <Paper className="interactive-progress-modal">
                    <div className="icon">
                        {progressModalProps.icon}
                    </div>
                    <Typography variant="h6">{progressModalProps.text}</Typography>
                </Paper>
            </Modal>
        </div>
    )
}