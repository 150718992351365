import React, { useState } from 'react';
import {
    FormControl,
    OutlinedInput,
    Button,
    Alert,
    Collapse,
    Typography,
    Snackbar,
} from '@mui/material';
import './ContactUsForm.index.less'; // Make sure to import your styles
import { contactUsAPIService } from '../../../services/contactUsAPIService';

const ContactUsForm = ({ onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(null);
    const [errors, setErrors] = useState({ email: null });
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Email validation function
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const onFormSubmit = async (event) => {
        event.preventDefault();
    
        // Reset errors
        setErrors({ email: null });
    
        // Validate email
        if (!email) {
            setErrors((prev) => ({ ...prev, email: ['Email is required.'] }));
            return;
        }
        if (!validateEmail(email)) {
            setErrors((prev) => ({ ...prev, email: ['Please enter a valid email address.'] }));
            return;
        }
    
        try {
            setOpenSnackbar(true);
            setStatus('Submitting your request...');
            await contactUsAPIService.submitContactUsAPIForm({
                'name': name,
                'email': email,
                'message': message,
            });
            setStatus("Thank you! We've received your query about the Marshal API. We'll be in touch shortly.");
            onClose();
        } catch (error) {
            // Failure case
            console.log('error: ', error);
            setStatus('We are facing some issues right now, please try again later.');
            onClose();
        } finally {
            // Ensure snackbar closes after operation
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000); // Adjust timeout as necessary
        }
    };
    

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className="contact-us">
            {/* <Typography variant="h5" gutterBottom>
                Contact Us
            </Typography> */}
            <form onSubmit={onFormSubmit} className="form">
                <FormControl fullWidth className="form-field" margin="normal">
                    {/* <label htmlFor="name" className="label-text">Name</label> */}
                    <OutlinedInput
                        id="name"
                        type="text"
                        placeholder="Your Name"
                        onChange={(event) => setName(event.target.value)}
                    />
                </FormControl>

                <FormControl fullWidth className="form-field" margin="normal">
                    {/* <label htmlFor="email" className="label-text">Email ID*</label> */}
                    <OutlinedInput
                        id="email"
                        type="email"
                        placeholder="Your Email Id*"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    {errors.email && (
                        <div style={{ color: 'red' }}>
                            <ul style={{ margin: '2px', paddingInlineStart: '5px', listStyleType: 'none' }}>
                                {errors.email.map((errorMsg, index) => (
                                    <li key={index} style={{ marginBottom: '5px' }}>{errorMsg}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </FormControl>

                <FormControl fullWidth className="form-field" margin="normal">
                    {/* <label htmlFor="message" className="label-text">Message</label> */}
                    <OutlinedInput
                        id="message"
                        type="text"
                        placeholder="Your Message"
                        multiline
                        rows={4}
                        onChange={(event) => setMessage(event.target.value)}
                    />
                </FormControl>

                <FormControl className="form-field button-field" margin="normal">
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        style={{ backgroundColor: '#236B48', color: '#fff', textTransform: 'none' }}
                    >
                        Submit
                    </Button>
                </FormControl>
            </form>

            <Collapse orientation="vertical" in={status !== null}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Alert sx={{ width: '360px' }} severity="success">
                        {status}
                    </Alert>
                </div>
            </Collapse>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message="Thank you for your interest! We have received your query and we'll be in touch shortly."
            />
        </div>
    );
};

export default ContactUsForm;
