import Axios from "../globals/axios/axios.index";

export const PinUnpinSessionService = {

    pinSession: (sessionId) => {
        const authToken = localStorage.getItem("authenticationToken");

        return Axios.post(`/api/v1/chat/pin-session/${sessionId}/pin/`, {}, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
        });
    },

    unpinSession: (sessionId) => {
        const authToken = localStorage.getItem("authenticationToken");

        return Axios.post(`/api/v1/chat/pin-session/${sessionId}/unpin/`, {}, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
        });
    }
};
