import React from "react";

export const RESET_STATE = "RESET_STATE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SET_HEADER_RIGHT_COMPONENT = "SET_HEADER_RIGHT_COMPONENT";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const resetState = () => ({
    type: RESET_STATE,
    payload: {},
});

export const closeNotification = () => ({
    type: CLOSE_NOTIFICATION,
    payload: {
        showNotification: false,
    },
});

export const setNotification = (
    showNotification: boolean,
    message: string,
    severity: string,
) => ({
    type: SET_NOTIFICATION,
    payload: {
        showNotification: showNotification,
        notificationMessage: message,
        notificationSeverity: severity,
    },
});


export const setHeaderRightComponent = (component: React.ReactElement) => ({
    type: SET_HEADER_RIGHT_COMPONENT,
    payload: {
        headerRightComponent: component
    }
})

export const setLanguage = (language: string) => ({
    type: SET_LANGUAGE,
    payload: {
        selectedLanguage: language,
    },
});