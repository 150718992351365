import Axios from "../globals/axios/axios.index";

export const CheckEmailService = {
    checkEmail: (email: string) => {
        const data: Record<any, any> = {
            email: email
        };

        return Axios.post("/api/v1/auth/verify-email/", data);
    }
};
