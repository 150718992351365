import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const RestartConversationConfirmationDialog: React.FC<DialogProps> = ({
    isOpen,
    onClose,
    onConfirm
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                Confirm
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Are you sure you want to restart the conversation?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                    size="small"
                    variant="text">
                    No
                </Button>
                <Button onClick={onConfirm}
                    size="small"
                    variant="contained" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RestartConversationConfirmationDialog;