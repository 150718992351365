import React from "react";
import "./t&cPage.index.less";
import fractalLogo from "../../assets/images/fractal-logo.png";
import { Paper, Typography } from "@mui/material";

const TermsOfUse = () => {
    // const authToken = localStorage.getItem("authenticationToken");
    // const authTokenIsValid = authToken !== null && authToken.trim().length > 0;

    // if (authTokenIsValid) {
    return (
        <Paper elevation={3} className="paperContainer">
            <div className="header">
                <img src={fractalLogo} alt="Logo" className="logo" />
                <hr className="line" />
            </div>
            <div>
                <h2>Terms and Conditions</h2>
            </div>
            <div>
                <h3>
                    Disclaimer - Limitations of Generative AI
                </h3>
                <p className="indent1">
                While we have exercised caution in developing the Application, it uses rapidly-evolving
                experimental technology.</p>
                <p className="indent1">
                Generative AI models have inherent limitations. These models may produce content that is
                creative, speculative, unexpected, inaccurate, erroneous or even offensive. Such content does
                not represent views of Fractal, its management or developers. The generated content is based
                on patterns learned from data and may not always align with user expectations. They may
                also exhibit biases or what one may refer to as an ‘error in judgment’. You are responsible for
                critically evaluating, ensuring the relevance of, and, if necessary, correcting any biases,
                incompleteness or errors in such generated content. The Marshall Bot is not intended for use
                in applications where errors or inaccuracies cannot be tolerated or may result in harm or
                financial loss.
                </p>
                <p className="indent1">
                Users must use discretion in and accept responsibility for relying on, or publishing, or
                otherwise using content provided by Marshall Bot.
                </p>
                <p className="indent1">Marshall Bot is not intended for providing medical, legal, financial, or other professional
                advice. Content generated by Marshall Bot cannot substitute professional advice.
                </p>
                <h3>
                    1.General
                </h3>
                <p className="indent1">
                    <strong>1.1</strong> By accessing, you conclude a legally binding agreement with us, which consists of:
                </p>
                <p className="indent2">
                    <strong>a.</strong> Terms and Conditions,
                </p>
                <p className="indent2">
                    <strong>b.</strong> our Privacy Policy;
                </p>
                <p className="indent2">
                    <strong>c.</strong> our Acceptable Use Policy;
                </p>
                <p className="indent2">
                    (Collectively, the “Agreement”)
                </p>
                <p className="indent1">
                    <strong>1.2</strong> If there is any inconsistency between the parts of this Agreement listed in Clause 1.1 above, the Agreement shall apply in the order of precedence as set out above.
                </p>
                <p className="indent1">
                    <strong>1.3</strong> You accept the Agreement without limitation or qualification and agree to comply with the Agreement at all times.
                </p>
                <p className="indent1">
                    <strong>1.4</strong> The Marshall Bot and MarshallGoldsmith.ai is created and managed by Fractal.
                </p>
                <h3>
                    2.Defintions
                </h3>
                <p>
                    Words and expressions in these Terms and Conditions shall have the following meanings unless the context otherwise requires:
                </p>
                <p className="indent1">
                    <strong>2.1“Account”/ “License”</strong> means the account created by the Individual for accessing the content or services;
                </p>
                <p className="indent1">
                    <strong>2.2	“Acceptable Use Policy”</strong> means the acceptable use policy which sets out the acceptable and unacceptable uses of Marshall Bot. Our Acceptable Use Policy can be found on the Website;
                </p>
                <p className="indent1">
                    <strong>2.3	“Affiliates”</strong> means an entity directly or indirectly controlling, controlled by, or under direct or indirect common control. In the case of Fractal’s affiliates, this definition shall include without limitation Fractal’s parent company, related companies and subsidiaries. “control” here means the power to direct the management and policies of such entity, directly or indirectly, whether through the ownership of voting securities, by contract or otherwise;
                </p>
                <p className="indent1">
                    <strong>2.4	“Authorised Users”</strong> means individuals who have created an account in Marshall Bot for the purpose of accessing its content in accordance with this Agreement;
                </p>
                <p className="indent1">
                    <strong>2.5	“Confidential Information”</strong> means all information (whether written or oral) which is not generally publicly available or is proprietary to the disclosing party. For the avoidance of doubt, Confidential Information in the case of Fractal as disclosing party, shall include without limitation, all know-how, trade secrets, financial, commercial, technical, tactical, or strategic information of any kind and information relating to its business, affairs, plans, customers, clients, suppliers, service providers, reports, recommendations, advice or tests, source and object codes of software incorporated into the Marshall Bot, any information obtained or received or accessed by you as a result of or in connection with the entry or performance of the Agreement.”;
                </p>
                <p className="indent1">
                    <strong>2.6	“Content”</strong> means any content, text, information, data, report, document, software, executable code, images, material and instructions, in whatever medium or form, provided by you to Fractal that are reasonably required by Fractal in order to enable you and/or the Authorised Users to use or access the Marshall Bot;
                </p>
                <p className="indent1">
                    <strong>2.7	“Data Protection Laws”</strong> means all applicable laws relating to data protection, including the collection, disclosure, use, storage, transfer or Processing of Personal Data, including and where applicable, the GDPR;
                </p>
                <p className="indent1">
                    <strong>2.8	“GDPR”</strong> means the General Data Protection Regulation (EU)
                </p>
                <p className="indent1">
                    <strong>2.9	“Intellectual Property Rights” </strong>shall include all copyright and moral rights, patents, trademarks, service marks, domain names, layout design rights, registered designs, design rights, database rights, trade or business names, know-how, rights protecting trade secrets and confidential information, rights protecting goodwill and reputation, and all other similar or corresponding proprietary rights and all applications for the same, whether presently existing or created in the future, anywhere in the world, whether registered or not, and all benefits, privileges, rights to sue, recovery of damages and obtainment of relief for any past, current or future infringement, misappropriation or violation of any of the foregoing rights;
                </p>
                <p className="indent1">
                    <strong>2.10 “Personal Data”</strong> shall have the meaning as prescribed by the applicable laws relating to data protection.
                </p>
                <p className="indent1">
                    <strong>2.11 “Privacy Policy” </strong>means Marshall Bot’s privacy policy found on the Website, as may be updated from time to time;
                </p>
                <p className="indent1">
                    <strong>2.12 “Services”</strong> means any service provides by Fractal to you in accordance with the Agreement including, where applicable, Support Services, and any other services provided through the Marshall Bot;
                </p>
                <p className="indent1">
                    <strong>2.13 “Sites”</strong> means any domain or application operated by Fractal from which the Services are available to Customers.
                </p>
                <p className="indent1">
                    <strong>2.14 “Fractal” </strong>means Fractal Analytics Inc, with a business address of 1 World Trade Center Suite 76J, New York  NY 10007.
                </p>
                <p className="indent1">
                    <strong>2.15 “Marshall Bot Website”</strong> means Fractal’s website: “MarshallGoldsmith.ai”;
                </p>
                <p className="indent1">
                    <strong>2.16 “Marshall Bot”</strong> means the Bot, and/or Services and/or other service, package, tool, content, support, technology offering (or a combination thereof), including updates and upgrades.
                </p>
                <h3>
                    3.Access and the use of the Services and Term
                </h3>
                <p className="indent1">
                    <strong>3.1</strong> Users must be at least eighteen (18) years of age to register and access an Account with Marshall Bot. Fractal does not intentionally provide its Services to individuals under the age of eighteen (18). If we become aware of a person under the age of eighteen (18) accessing or using Marshall Bot, we will take the following actions:
                </p>
                <p className="indent2">
                    <strong>•</strong> Account Termination: We will promptly prohibit and block such Account without prior notice.
                </p>
                <p className="indent2">
                    <strong>•</strong>	Data Deletion: We will make all reasonable efforts to promptly delete any data associated with such Account.
                </p>
                <p className="indent2">
                    Marshall Bot assumes no liability towards individuals found to be in violation of this age requirement.
                </p>
                <p className="indent1">
                    <strong>3.2</strong> When creating an Account and during the term of the Agreement, you must provide and keep the Account information true, accurate, current, and complete about yourself as requested to create and maintain the Account.
                </p>
                <p className="indent1">
                    <strong>3.3</strong> Subject to and your compliance with the Agreement, Fractal hereby grants you a non-exclusive, non-transferable, royalty-free, limited and revocable license to access, and use the Marshall Bot made available to you and/or permit the Authorised Users to use the Marshall Bot.
                </p>
                <p className="indent1">
                    <strong>3.4</strong> The rights provided under this clause are granted to you only and shall not be considered granted to any of your Affiliates or any other third parties, unless otherwise agreed in writing by Marshall Bot.
                </p>
                <p className="indent1">
                    <strong>3.5</strong> You shall be liable for all activities conducted using the Marshall Bot and you shall not use the Marshall Bot in any way that adversely prejudices and/or harms Marshall Bot and/or its Affiliates and their respective officers, employees, agents, partners or customers.
                </p>
                <h3>
                    4.Product Revisions
                </h3>
                <p className="indent1">
                    <strong>4.1</strong> Fractal reserves the right to vary types and quantities of add-ons, functions, features, services that may be offered to you by Fractal at any time without prior notice to you.
                </p>
                <h3>
                    5.Representations and Warranties
                </h3>
                <p className="indent1">
                    <strong>5.1</strong> You shall:
                </p>
                <p className="indent2">
                    <strong>a.</strong> comply with the Agreement in connection with the use of the Marshall Bot. Notwithstanding anything to the contrary in this Agreement, you acknowledge and agree that you shall remain fully responsible to Fractal for the Authorised Users’ acts and omissions in connection with the use of the Marshall Bot;
                </p>
                <p className="indent2">
                    <strong>b.</strong> ensure that all Content supplied to Fractal is not: (i) unlawful or fraudulent; (ii) has not been uploaded or provided for an improper purpose; (iii) does not contain any discriminatory, defamatory, abusive, inappropriate, obscene or offensive language; (iv) is true, current and accurate; (v) does not contain any Virus and/or Vulnerability; (vi) does not provide any false or misleading information or misrepresent any law or fact, or overstate or convey a false impression of any relevant information; (vii) is not disparaging or prejudicial to or shall negatively affect Fractal or its reputation; (viii) does not exploit Fractal’s business, or cause Fractal to be exposed to liability and/or reputational damage; and
                </p>
                <p className="indent2">
                    <strong>c.</strong> use all reasonable endeavours to prevent any unauthorised access to, or use of, the Marshall Bot and, in the event of any such unauthorised access or use, promptly notify Fractal.
                </p>
                <p className="indent1">
                    <strong>5.2</strong> You shall not:
                </p>
                <p className="indent2">
                    <strong>a.</strong> license, sub-license, sell, rent, lease, transfer, assign, access, or otherwise commercially exploit the Marshall Bot;
                </p>
                <p className="indent2">
                    <strong>b.</strong> copy,  modify, duplicate, frame, mirror, republish, display, all or any portion of the Marshall Bot or any technology or system used by Fractal in connection with providing the Product, in any form or media or by any means;
                </p>
                <p className="indent2">
                    <strong>c.</strong> de-compile, reverse compile, disassemble, reverse engineer, discover any trade secret contained in or otherwise reduce to human-perceivable form all or any part of Marshall Bot or other technology and/or system under the suite of the Marshall Bot;
                </p>
                <p className="indent2">
                    <strong>d.</strong> use  the Marshall Bot to process, transmit or otherwise make available any content and/or material that infringes or misappropriates the Intellectual Property Rights and/or proprietary rights of any third-party;
                </p>
                <p className="indent2">
                    <strong>e.</strong> build a product or service using similar ideas, features, functions or graphics to the Marshall Bot; and
                </p>
                <p className="indent2">
                    <strong>f.</strong> introduce or permit the introduction of, any Virus and/or Vulnerability into the Marshall Bot, network, or information systems, including but not limited to the Marshall Bot and/or any other products.
                </p>
                <p className="indent1">
                    <strong>5.3</strong> You represent and warrant to us that at all times:
                </p>
                <p className="indent2">
                    <strong>a.</strong> you hold all necessary authorisations, licenses, consents, approvals, to publish or disseminate the Content to Fractal, and to input into the Marshall Bot and/or the Product the Content without infringing the rights of any third-party, including any third-party Intellectual Property Rights, and without violating any law, and all Content submitted by you to Fractal does not violate or infringe upon any common law or statutory rights of any party including, without limitation, contractual rights, copyright, and rights of privacy, and there shall not be contained in or incorporated into the Content and each and every part provided by you to Fractal, anything which would infringe or misappropriate any Intellectual Property Rights, any other personal or proprietary right, or any publicity or privacy right of any third-party and no third-party shall have any right to assert any claim of ownership or for infringement or misappropriation of any Intellectual Property Right as to any portion of the Content;
                </p>
                <p className="indent2">
                    <strong>b.</strong> you will comply with all applicable laws, rules, and regulations and that you will not use or
                    prompt the Marshall Bot to perform or facilitate dangerous, illegal, or malicious activities,
                    including generating the following categories of content-
                </p>
                <p className="indent3">
                <p className="indent3"></p><strong>1.   Harm.</strong> Content that may harm or promote the harm of individuals or a group, such as content that may insult, defame, harass or have unfair or adverse impact on people, especially those having sensitive or protected characteristics;
                <p className="indent3"></p><strong>2.	Harassment.</strong> Content that promotes or encourages hatred; harasses or bullies to intimidate, abuses, or insults others, including on the basis of gender, religion, race and ethnicity;
                <p className="indent3"></p><strong>3.	Violence.</strong> Content that may facilitate, promote, or incite violence; encourage self-harm; 
                <p className="indent3"></p><strong>4.	Sexually-explicit.</strong> Content that is sexually explicit, including content created for the purposes of pornography or sexual gratification;
                <p className="indent3"></p><strong>5.	Obscenity.</strong> Content that is obscene, pornographic, paedophilic, invasive of another’s privacy, including bodily privacy; 
                <p className="indent3"></p><strong>6.	Misinformation.</strong> Content that may be used to disseminate information, which is patently false and untrue, and intentionally communicates any misinformation. This includes generating content that may be a threat to the integrity of any electoral process;
                <p className="indent3"></p><strong>7.	Misrepresentation.</strong> Content that is intended to misinform, misrepresent or mislead about the provenance of generated content by claiming content was created by a human, or represent generated content as original works, in order to deceive.
                <p className="indent3"></p><strong>8.	Impersonation.</strong> Content that impersonates an individual (living or dead) without explicit disclosure, in order to deceive;
                <p className="indent3"></p><strong>9.	National integrity.</strong> Content that threatens the unity, integrity, defence, security or sovereignty of any country, friendly relations with foreign States, or public order, or is insulting other nation

                </p>
                <p className="indent2">
                    <strong>c.</strong> you will comply with all your obligations set out in the Agreement; and
                </p>
                <p className="indent2">
                    <strong>d.</strong> to review the Content, and the inclusion of the Content by Fractal in the Marshall Bot
                </p>
                <h3>
                    6.Intellectual Property
                </h3>
                <p className="indent1">
                    <strong>6.1</strong> Content Ownership and Copyright: All content published or accessed through Marshall Bot is the exclusive property of Marshall Goldsmith and/or its content creators, protected by international copyright laws.
                </p>

                <p className="indent1">
                    <strong>6.2</strong> Ownership of Marshall Bot and Intellectual Property: You acknowledge and agree that Fractal owns and retains all rights, titles, and interests in and to Marshall Bot, including its Sites and Services, encompassing all Intellectual Property Rights, methods, materials, technologies, tools (including software tools), design code, templates, applications, techniques, and other know-how developed by or for Fractal. Fractal shall maintain ownership of all rights, titles, and interests, including all intellectual property rights, in any improvements to Marshall Bot and any new APIs, programs, upgrades, modifications, or enhancements to Marshall Bot developed by Fractal, whether developed for you or at your request.
                </p>
                <p className="indent1">
                    <strong>6.3</strong> Limitations on Intellectual Property Rights: Except as expressly stated herein, this Agreement does not grant you any rights to, under, or in Intellectual Property Rights or any other rights or licenses related to Marshall Bot. Without prior written consent from Fractal, you may not use any of Fractal's Intellectual Property Rights. All other names, products, and marks mentioned are the Intellectual Property Rights of their respective owners. Fractal reserves all rights not expressly granted herein.
                </p>
                <p className="indent1">
                    <strong>6.4</strong> Restrictions on Use of Intellectual Property Rights: This Agreement does not grant you any right, license, interest, or permission to use or deal with any Intellectual Property Rights of Fractal and in any way. This includes the right to copy, transfer, publish, store, create derivative works, or use any of Intellectual Property Rights (including registered and unregistered trademarks, trade names and content) in an unauthorized manner.
                </p>
                <p className="indent1">
                    <strong>6.5</strong> Reporting Intellectual Property Concerns: You shall promptly notify us if you become aware of any matter or Content that may infringe the Intellectual Property Rights of a third-party.
                </p>
                <h3>
                    7.Third Party Code &API Usage
                </h3>
                <p className="indent1">
                    <strong>7.1</strong> Marshall Bot may utilize third-party code, libraries, and application programming interfaces (APIs) to enhance its functionality and provide additional features to users.
                </p>

                <p className="indent1">
                    <strong>7.2</strong> Marshall Bot makes no warranties or representations regarding the performance, security, reliability, or compatibility of any third-party code or API used within the service. Users acknowledge and agree that the use of such third-party resources is at their own risk.
                </p>
                <h3>
                    8.Indemnities and Limitation of Liability
                </h3>
                <p className="indent1">
                    <strong>8.1</strong> You shall indemnify and hold harmless Fractal and its Affiliates and its and their respective directors, officers, employees, agents, contractors, third-party service providers and partners from and againstany and all liabilities, actions, proceedings, claims, demands, costs and expenses (including legal expenses) arising out of or in connection with
                </p>
                <p className="indent2">
                    <strong>a.</strong> your use of the Marshall Bot provided by Fractal to you;
                    </p>
                <p className="indent2">
                    <strong>b.</strong> the Content;
                </p>
                <p className="indent2">
                    <strong>c.</strong> any reports, declarations, calculations, or other information that are produced through, derived from, or otherwise connected to Marshall Bot.
                
                </p>
                <p className="indent2">
                    <strong>d.</strong> Your violation of the Agreement;
                    </p>
                <p className="indent2">
                    <strong>e.</strong> . claims from third parties arising out of your cancellation and/or termination of the Agreement; and/or
                </p>
                <p className="indent2">
                    <strong>f.</strong> any negligent act, omission or wilful conduct, misconduct or fraud of you, or your Authorised Users, officers, employees, agents, or independent contractors; and
                </p>
                <p className="indent2">
                    <strong>g.</strong>  any dispute, contention, or claim of ownership of the Content.
                </p>
                <p className="indent1">
                    <strong>8.2</strong> You shall indemnify Fractal against any and all liability, loss, damage, costs and expenses which Fractal or a third-party may incur or suffer whether direct or consequential (including any economic loss or other loss of profits, business or goodwill) as a result of any dispute or contractual, tortious or other claims or proceedings brought against Fractal by a third-party alleging infringement of its Intellectual Property Rights by reason of your use or exploitation of the Marshall Bot and/or the Content.
                </p>
                <p className="indent1">
                    <strong>8.3</strong> Each indemnity in these Terms and Conditions is a continuing obligation and survives expiry or termination of the Agreement or expiry of any Subscription Term.
                </p>
                <p className="indent1">
                    <strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FRACTAL’S AGGREGATE LIABILITY FOR ANY CLAIMS UNDER OR PURSUANT TO THE AGREEMENT EXCEED THE AGGREGATE FEES ACTUALLY PAID BY YOU FOR THE PRECEDING TWELVE (12) MONTH PERIOD AT THE POINT IN TIME WHEN THE CLAIM(S) IS/ARE MADE AGAINST FRACTAL.</strong>
                </p>
                <p className="indent1">
                    <strong>8.4</strong> 8.4	Notwithstanding any other provision in the Agreement and to the maximum extent permitted by applicable law, under no circumstances shall Fractal be liable for any direct, indirect, consequential or special loss or damage relating to (i) disruptions or interruptions to the internet that may affect the use of the Marshall Bot; (ii) errors, delays or technological failures that may prevent Fractal from providing the Marshall Bot or related services; (iii) loss of your data or Content; (iv) damage, disruption or injury to your webpage or website; and (v) any delay or failure in performance caused by events beyond the reasonable control of Fractal, even if Fractal was advised of the possibility of such damages or if such possibility was reasonably foreseeable.
                </p>
                <h3>
                    9.Disclaimers
                </h3>
                <p className="indent1">
                    <strong>9.1</strong> You expressly understand and agree that:
                </p>
                <p className="indent2">
                    <strong>a. YOUR USE OF THE MARSHALL BOT AND SERVICES, IS AT YOUR OWN RISK. THE MARSHALL BOT AND SERVICES ARE PROVIDED BY FRACTAL ON AN “AS IS” BASIS. FRACTAL EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED BY STATUTE, COMMON LAW OR OTHERWISE, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, RELIABILITY, PERFORMANCE, SECURITY, CONTINUED AVAILABILITY, OR INTER-OPERABILITY WITH OTHER SYSTEMS OR SERVICES;</strong>
                </p>
                <p className="indent2">
                    <strong>b.</strong> Fractal makes no warranty that, except as expressly stated herein this Agreement:
                </p>
                <p className="indent3">
                    <strong>i.</strong> The Marshall bot will meet your requirements;
                </p>
                <p className="indent3">
                    <strong>ii.</strong> your use of the Marshall and/or the Services will be uninterrupted, timely, secure, accurate and error-free;
                </p>

                <p className="indent3">
                    <strong>iii.</strong> the Marshall Bot and/or Services will be accessible at any time or at all times via the channel selected or used by you;
                </p>

                <p className="indent3">
                    <strong>iv.</strong> the quality of the Marshall Bot, information or other material purchased or obtained by you from Fractal will meet your expectations;
                </p>

                <p className="indent3">
                    <strong>v.</strong> any errors in the Marshall Bot and/or Services will be corrected; and
                </p>

                <p className="indent3">
                    <strong>vi.</strong> the information and content provided on the Marshall Bot is complete, accurate, or current;
                </p>
                <p className="indent1">
                    <strong>9.2</strong> For the avoidance of doubt, no advice or information, whether oral or written, obtained by you from Fractal or its employees or agents shall create any condition, warranty or guarantee not expressly stated in the Agreement.
                </p>
                <h3>
                    10.Personal Information and Personal Data
                </h3>
                <p className="indent1">
                    <strong>10.1</strong> You acknowledge that Fractal may collect, use, store, and disclose your Personal Data for the purposes as set out in our Privacy Policy, and all Personal Data provided by you is subject to our Privacy Policy.
                </p>

                <p className="indent1">
                    <strong>10.2</strong> Fractal shall comply with the Data Protection Laws relating to the collection, use, disclosure and retention of Personal Data.
                </p>
                <p className="indent1">
                    <strong>10.3</strong> You undertake that you will comply with all Data Protection Laws. If you provide to Fractal any Personal Data relating to a third-party (including in the Customer Personal Data), by submitting such information to us, you warrant and represent to us that you have obtained the consent of such third-party to you providing us with their Personal Data for the collection, use, and disclosure of their Personal Data for all purposes set out in our Privacy Policy, by or for the benefit of the persons referenced in the same, and the provision of the Product to you.
                </p>
                <h3>
                    11.Term and Termination
                </h3>
                <p className="indent1">
                    <strong>11.1</strong> Fractal may immediately terminate any Account/ License and/or the Agreement, terminate or suspend your access to all or part of the Marshall Bot;
                </p>
                <p className="indent2">
                    <strong>a.</strong> if you commit a material breach of any of your obligations under the Agreement;
                </p>
                <p className="indent2">
                    <strong>b.</strong> if any of the representations or warranties you make pursuant to the Agreement are incorrect;
                </p>
                <p className="indent2">
                    <strong>c.</strong> if required by any law or regulation, or by any enforcement or other government agency or regulatory authority;
                </p>
                <p className="indent2">
                    <strong>d.</strong> if you or your Content infringe any Intellectual Property Rights of Fractal or any third-party;
                </p>
                <p className="indent2">
                    <strong>e.</strong> in the event of discontinuance or material modification to the Marshall Bot, the sites and/or the Services or part thereof;
                </p>
                <p className="indent2">
                    <strong>f.</strong> in the event of any unexpected technical or security issues; and
                </p>
                <p className="indent2">
                    <strong>g.</strong> in the event of a Security Event;
                </p>

                <p className="indent1">
                    <strong>11.2</strong> Termination of Agreement shall be without prejudice to any other rights or remedies Fractal may be entitled to under the Agreement, at law or in equity and shall not affect any accrued rights or liabilities nor the coming into or continuance in force of any provision which is expressly or by implication intended to come into or continue in force on or after such termination.
                </p>
                <h3>
                    12.Confidentiality
                </h3>
                <p className="indent1">
                    <strong>12.1</strong> You agree to keep confidential, and to procure that the Authorised Users keep confidential, any Confidential Information, and shall not, disclose the Confidential Information to any other person unless disclosure has been expressly permitted by Fractal in writing. You agree and acknowledge that Confidential Information shall only be used for the purposes of the Agreement and/or in performing your obligations under the Agreement.
                </p>
                <p className="indent1">
                    <strong>12.2</strong> You shall immediately notify Fractal if you suspect, or become aware of, any unauthorised reproduction, use, disclosure, transfer or storage of Confidential Information.
                </p>
                <p className="indent1">
                    <strong>12.3</strong> The provisions of this clause shall survive and continue to remain in full force and effect notwithstanding any expiry or termination of the Agreement.
                </p>
                <h3>
                    13.Reporting
                </h3>
                <p className="indent1">
                    <strong>13.1</strong> You are solely responsible for the preparation of any report. While Fractal may provide tools and services in the Marshall Bot and/or the Sites to assist in your reporting, you acknowledge and agree that any tools and services provided to you are provided merely as a convenience to you and that you retain sole responsibility for ensuring the timeliness, accuracy, and completeness of any reports you submit.
                </p>
                <h3>
                    14.Anti-Bribery and Corruption
                </h3>
                <p className="indent1">
                    <strong>14.1</strong> You represent and covenant that you will:
                </p>
                <p className="indent2">
                    <strong>a.</strong> comply with all anti-bribery laws and regulations;
                </p>
                <p className="indent2">
                    <strong>b.</strong> not engage in, encourage, permit, condone, or tolerate any form of bribery and corruption whatsoever, whether direct or indirect, and whether financial in nature or otherwise;
                </p>
                <p className="indent2">
                    <strong>c.</strong> not do, or omit to do, anything that may cause Fractal to be in breach of any anti-bribery laws and regulations; and
                </p>
                <p className="indent2">
                    <strong>d.</strong> maintain and enforce adequate and effective anti-bribery and corruption policies and procedures designed to promote and ensure that you shall at all times comply with applicable anti-bribery laws and regulation.
                </p>
                <p className="indent1">
                    <strong>14.2</strong> To the extent permitted by law, you shall promptly notify Fractal of any investigation by a governmental authority (including without limitation, regulatory agencies) or violations by you of any law or regulation with respect to anti-bribery or anti-corruption laws and any matters relating in any way to the Agreement or which could potentially impact the continuity of your performance of the Agreement.
                </p>
                <h3>
                    15.Force Majeure
                </h3>
                <p className="indent1">
                    <strong>15.1</strong> No failure or omission by Fractal to carry out its obligations or observe any of the stipulations or conditions of the Agreement, shall give rise to any claims against Fractal or be deemed a breach of the Agreement, in the event that such failure or omission arises from a cause of force majeure, which includes acts of God, new statutory enactments or modifications, war or warlike hostilities, pandemics, epidemics, acts of terrorism, civil commotion, riots, blockades, embargoes, sabotage, strikes, lockouts, shortage of material or labour, delay in deliveries from sub-contractors, machine failure caused by force majeure, or any other event that is unforeseeable and outside the reasonable control of Fractal. Upon the occurrence of any event mentioned in this clause, Fractal shall for the duration of such event(s) be relieved of any obligation under the Agreement as is affected by the event(s) save that the provisions of the Agreement shall remain in force with regard to all other obligations under the Agreement which are not affected by the event(s).
                </p>
                <h3>
                    16.Notices
                </h3>
                <p className="indent1">
                    <strong>16.1</strong> All notices from you to Fractal regarding the Agreement shall be sent via e-mail to <strong> </strong> (copying <strong>legal@fractal.ai</strong>). Communications delivered by email shall be effective when actually received by Fractal in readable form and subject to there being no bounce-back notification and/or sending failure notification.
                </p>
                <p className="indent1">
                    <strong>16.2</strong> Fractal will send notices and other communications to you at the email address you have provided to us in your account. It is your sole responsibility to ensure that you provide Fractal with your current contact email address.
                </p>
                <h3>
                    17.General
                </h3>
                <p className="indent1">
                    <strong>17.1 Entire Agreement:</strong> The Agreement constitute the entire agreement between Fractal and you in relation to their subject matter and supersede any prior agreements, discussions, representations and undertakings between the parties (whether written or oral).
                </p>
                <p className="indent1">
                    <strong>17.2 Variation:</strong> Fractal may amend the Agreement at any time and will provide a notice to you. The amended Agreement will be made available on the Platform (as defined below). You are advised to check for amendments to the Agreement regularly, prior to using the Marshall Bot. Your continued use of the Marshall Bot shall constitute your agreement to the revised Agreement.
                </p>
                <p className="indent1">
                    <strong>17.3 No Waiver:</strong> Any failure by either Parties to exercise or enforce any right or provision of the Agreement shall not constitute a waiver of such right or provision. The rights and remedies provided in the Agreement are cumulative and not exclusive of any rights or remedies (whether provided by law or otherwise).
                </p>
                <p className="indent1">
                    <strong>17.4 Illegality:</strong> Should any provision of the Agreement be held invalid, illegal or unenforceable in any respect under the law of any jurisdiction, the remaining provisions of the Agreement shall be unaffected and shall remain in full force and effect and the Agreement will be amended to give effect to the eliminated provision to the maximum extent possible.
                </p>
                <p className="indent1">
                    <strong>17.5 Third Party Rights:</strong> Except as expressly set out in the Agreement, any person not a party to the Agreement shall acquire no rights whatsoever under the Agreement.
                </p>
                <h3>
                    18.Governing Law and Dispute Resolution
                </h3>
                <p className="indent1">
                    <strong>18.1</strong> The Agreement shall be governed by and construed in accordance with the laws of the United States, and any claims or disputes of whatever nature shall be subject to the exclusive jurisdiction of the courts in New York.
                </p>

            </div>
        </Paper>
    )
    // }
    // else {
    //     return (
    //         <Paper className="no-login-screen">
    //             <Typography variant="body1" align="center">
    //                 Please Login to see the Terms of Use!
    //             </Typography>
    //         </Paper>
    //     )
    // }
}

export default TermsOfUse