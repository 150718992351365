import { Dispatch } from "redux";
import appStateModel from "../../globals/redux/models";
import * as RegisterActions from "./register.actions";
import { RegistrationService } from "../../services/registrationService"
import { VerifyOtpService } from "../../services/verifyOtpService";
import dayjs from "dayjs";

export const registerUser = (username: string, email:string, first_name:string, last_name:string, password: string) => {
    return async (dispatch: Dispatch<any>, getState: () => appStateModel) => {
        try {
            dispatch(RegisterActions.setRegistrationStatus("in progress"));
            const response = await RegistrationService.registration(username, email, first_name, last_name, password);
            dispatch(RegisterActions.setRegistrationStatus("successful"));
        } catch (error) {
            dispatch(RegisterActions.setRegistrationStatus("failed"));
            throw error;
        }
    };
};

