import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import store from "./globals/redux/store/store.index";
import { Provider } from "react-redux";
import AppRouter from "./navigation";
import "./globals/styles/styles.less";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./globals/themes/theme";


const root = createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <AppRouter />
        </ThemeProvider>
    </Provider>
);

reportWebVitals();
