export const SET_FEEDBACK_CALL_API_CALL_STATUS = "FEEDBACK/SET_FEEDBACK_CALL_API_CALL_STATUS";
export const RESET_STATE = "FEEDBACK/RESET_STATE";

export const setFeedbackApiCallStatus = (status: string) => ({
    type: SET_FEEDBACK_CALL_API_CALL_STATUS,
    payload: {
        userFeedbackApiCallStatus: status
    }
});

export const resetState = () => ({
    type: RESET_STATE,
    payload: {
        userFeedbackApiCallStatus: null
    }
})