import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
const middlewares = [thunk];
const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();